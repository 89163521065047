import {
  LinkeDinIcons,
  FaceBookIcons,
  TwitterIcons,
  InstagramIcons,
  PinterestIcon,
  SquareFbIcon,
  TwitterIcon,
  LinkedinIcon,
  InstagramIcon,
  ViewExternalLink,
  CopyIcon,
  PauseIcon,
  CloseCircleIcon,
  DeleteIcon,
  EditFileIcons,
} from '../theme/images';

export const sortOptions = [
  { value: '-created_at', label: 'Recently Added' },
  { value: '-updated_at', label: 'Last Modified' },
  { value: 'expiring_soon', label: 'Expiry Date' },
];

export const performanceSortOptions = [
  {
    value: 'revenue',
    label: 'Revenue',
    key: 'revenue',
    order: true,
    class: 'revenue',
    default: true,
  },
  {
    value: 'units_sold',
    label: 'Units Sold',
    key: 'unitsSold',
    order: true,
    class: 'units_sold',
    default: true,
  },
  {
    value: 'traffic',
    label: 'Traffic',
    key: 'traffic',
    order: true,
    class: 'traffic',
    default: true,
  },
  {
    value: 'conversion',
    label: 'Conversion',
    key: 'conversion',
    order: true,
    class: 'conversion',
    default: true,
  },
];

export const sortByOrderOptions = [
  { value: true, label: 'Highest to Lowest' },
  { value: false, label: 'Lowest to Highest' },
];

export const sadSortOptions = [
  {
    value: 'ad_sales',
    label: 'Ad Sales',
    key: 'adSales',
    order: true,
    class: 'revenue',
    default: true,
  },
  {
    value: 'ad_spend',
    label: 'Ad Spend',
    key: 'adSpend',
    order: true,
    class: 'units_sold',
    default: true,
  },
  {
    value: 'impressions',
    label: 'Impressions',
    key: 'impressions',
    order: true,
    class: 'traffic',
    default: true,
  },
  {
    value: 'acos',
    label: 'ACos',
    key: 'adCos',
    order: true,
    class: 'conversion',
    default: true,
  },
];

export const dadSortOptions = [
  {
    value: 'impressions',
    label: 'Impressions',
    key: 'dspImpressions',
    order: true,
    class: 'revenue',
    default: true,
  },
  {
    value: 'dsp_spend',
    label: 'DSP Spend',
    key: 'dspSpend',
    order: true,
    class: 'units_sold',
    default: true,
  },
  {
    value: 'total_product_sales',
    label: 'Total Product Sales',
    key: 'dspTotalProductSales',
    order: true,
    class: 'traffic',
    default: true,
  },
  {
    value: 'total_roas',
    label: 'Total ROAS',
    key: 'dspTotalRoas',
    order: true,
    class: 'conversion',
    default: true,
  },
];
export const vendorSalesPerformanceOptions = [
  {
    id: 'orderedRevenueCheck',
    value: 'ordered_revenue_tab',
    label: 'Ordered Revenue',
    order: true,
    class: 'orderedRevenueTab',
    default: true,
    dashboard: 'ordered_revenue',
  },
  {
    id: 'shippedCOGSCheck',
    value: 'shipped_cogs_tab',
    label: 'Shipped COGs',
    order: true,
    class: 'shippedCogsTab',
    default: true,
    dashboard: 'shipped_cogs',
  },
];

export const columnsForVendorSalesPerformance = {
  ordered_revenue_tab: [
    {
      value: 'ordered_revenue',
      label: 'Ordered Revenue',
      key: 'orderedRevenue',
      order: true,
      class: 'revenue',
      default: true,
      dashboard: 'ordered_revenue',
    },
    {
      value: 'conversion_rate',
      label: 'Conversion Rate',
      key: 'conversionRate',
      order: true,
      class: 'units_sold',
      default: true,
      dashboard: 'ordered_revenue',
    },
    {
      value: 'glance_views',
      label: 'Glance Views',
      key: 'glanceViews',
      order: true,
      class: 'traffic',
      default: true,
      dashboard: 'ordered_revenue',
    },
    {
      value: 'ordered_units',
      label: 'Ordered Units',
      key: 'orderedUnits',
      order: true,
      class: 'conversion',
      default: true,
      dashboard: 'ordered_revenue',
    },
  ],
  shipped_cogs_tab: [
    {
      value: 'shipped_cogs',
      label: 'Shipped COGs',
      key: 'shippedCOGs',
      order: true,
      class: 'revenue',
      default: true,
      dashboard: 'shipped_cogs',
    },
    {
      value: 'glance_views',
      label: 'Glance Views',
      key: 'glanceViews',
      order: true,
      class: 'units_sold',
      default: true,
      dashboard: 'shipped_cogs',
    },
    {
      value: 'shipped_units',
      label: 'Shipped Units',
      key: 'shippedUnits',
      order: true,
      class: 'traffic',
      default: true,
      dashboard: 'shipped_cogs',
    },
    {
      value: 'conversion_rate',
      label: 'Conversion Rate',
      key: 'conversionRate',
      order: true,
      class: 'conversion',
      default: true,
      dashboard: 'shipped_cogs',
    },
  ],
};
export const columnsForSalesPerformance = [
  ...performanceSortOptions,
  {
    value: 'ad_spend_total',
    label: 'Total AD Spend',
    key: 'adSpendTotal',
    order: true,
    class: 'total-ad-spend',
  },
  {
    value: 'tacos',
    label: 'TACOS',
    key: 'tacos',
    order: true,
    class: 'tacos',
  },
];
export const columnsForSponsoredPerformance = [
  ...sadSortOptions,

  {
    value: 'ad_conversion_rate',
    label: 'Ad conversion rate',
    key: 'adConversion',
  },

  { value: 'cost_per_click', label: 'Cost per click', key: 'costPerClick' },
  { value: 'sales_per_click', label: 'Sales per click', key: 'salesPerClick' },
  { value: 'roas', label: 'ROAS', key: 'adRoas' },
  { value: 'tacos', label: 'TACOS', info: 'Seller ONLY', key: 'tacos' },
  { value: 'clicks', label: 'Clicks', key: 'adClicks' },
  { value: 'ctr', label: 'Click through rate', key: 'adClickRate' },
  { value: 'num_orders', label: 'Orders', key: 'adOrders' },
  { value: 'ntb_orders', label: 'NTB orders', key: 'ntbOrders' },
  { value: 'ntb_sales', label: 'NTB Sales', key: 'ntbSales' },
  {
    value: 'ntb_orders_percent',
    label: '% of orders NTB',
    key: 'percentageNtbOrders',
  },
  {
    value: 'ntb_sales_percent',
    label: '% of sales NTB',
    key: 'percentageNtbSales',
  },
  {
    value: 'ad_spend_total',
    label: 'Total AD Spend',
    info: 'Seller ONLY',
    key: 'adSpendTotal',
  },
];

export const columnsForDSPPerformance = [
  ...dadSortOptions,
  { value: 'total_dpvr', label: 'Total DPVR', key: 'dspTotalDpvr' },
  {
    value: 'total_new_to_brand_purchases',
    label: 'TTL new to brand purchases',
    key: 'dspTtlNewBrandPurchases',
  },
  { value: 'product_sales', label: 'Product Sales', key: 'dspProductSales' },

  { value: 'roas', label: 'ROAS', key: 'dspRoas' },
];

export const timeFrameFilters = [
  { value: 'week', label: 'Current 7 days' },
  { value: 'month', label: 'Current Month' },
  { value: '30days', label: 'Current 30 days' },
  {
    value: 'custom',
    label: 'Custom Period',
    sub: 'Select start and end dates',
  },
];

// needs a name change
export const options = [
  { value: 'contract_details', label: 'Accounts' },
  { value: 'performance', label: 'Sales Performance' },
  { value: 'sponsored_ad_performance', label: 'Sponsored Ad Performance' },
  { value: 'dsp_ad_performance', label: 'DSP Ad Performance' },
];

export const editCompanyFields = [
  {
    key: 'description',
    label: 'Description',
    type: 'textarea',
    property: 'col-md-12',
  },
  { key: 'phone_number', label: 'Phone', property: 'col-md-12', type: 'text' },
  {
    key: 'social',
    label: 'Social',

    type: 'social',
    property: 'col-md-12',
    choices: [
      { key: 'facebook', item: FaceBookIcons, label: 'Facebook' },
      { key: 'twitter', item: TwitterIcons, label: 'Twitter' },
      { key: 'linkedin', item: LinkeDinIcons, label: 'LinkedIn' },
      { key: 'instagram', item: InstagramIcons, label: 'Instagram' },
      { key: 'pinterest', item: PinterestIcon, label: 'Pinterest' },
    ],
  },
];

export const editAccountFields = [
  {
    key: 'company_name',
    label: 'Company Name',
    property: 'col-9',
    type: 'text',
  },
  { key: 'website', label: 'Website', type: 'text', property: 'col-md-6' },
  {
    key: 'country',
    label: 'Country',
    type: 'choice',
    property: 'col-md-6',
  },
  {
    key: 'address',
    label: 'Address',
    property: 'col-12',
    type: 'text',
  },
  {
    key: 'city',
    label: 'City',
    type: 'text',
    property: 'col-md-6',
  },
  {
    key: 'state',
    label: 'State',
    type: 'text',
    property: 'col-md-6',
  },

  {
    key: 'zip_code',
    label: 'Postal Code',
    property: 'col-md-12',
    type: 'text',
  },
];

export const socialIcons = [
  {
    key: 'facebook',
    value: FaceBookIcons,
    label: 'Facebook',
    onboard: SquareFbIcon,
    property: 'social-media face-book mr-1',
  },
  {
    key: 'twitter',
    value: TwitterIcons,
    label: 'Twitter',
    onboard: TwitterIcon,
    property: 'social-media mr-1',
  },
  {
    key: 'linkedin',
    value: LinkeDinIcons,
    label: 'LinkedIn',
    onboard: LinkedinIcon,
    property: 'social-media linedin mr-1',
  },
  {
    key: 'instagram',
    value: InstagramIcons,
    label: 'Instagram',
    onboard: InstagramIcon,
    property: 'social-media  insta mr-1',
  },
  {
    key: 'pinterest',
    value: PinterestIcon,
    label: 'Pinterest',
    onboard: PinterestIcon,
    property: 'social-media  insta mr-1',
  },
];

export const contractOptions = [
  { value: 'view', label: 'View Agreement', icon: ViewExternalLink },
  { value: 'addendum', label: 'Draft Addendum', icon: EditFileIcons },
  { value: 'pause', label: 'Pause Agreement', icon: PauseIcon },
  { value: 'cancel', label: 'Cancel Agreement', icon: CloseCircleIcon },
];

export const contractOptionsWithAddendum = [
  { value: 'view', label: 'View Agreement', icon: ViewExternalLink },
  { value: 'addendum', label: 'Draft Addendum', icon: EditFileIcons },
];

export const contractOptionsWithoutAddendum = [
  { value: 'view', label: 'View Agreement', icon: ViewExternalLink },
  { value: 'pause', label: 'Pause Agreement', icon: PauseIcon },
  { value: 'cancel', label: 'Cancel Agreement', icon: CloseCircleIcon },
];

export const contractOptionsWithoutPauseCancel = [
  { value: 'view', label: 'View Agreement', icon: ViewExternalLink },
];

export const draftContractOptions = [
  { value: 'view', label: 'View Agreement', icon: ViewExternalLink },
  { value: 'edit', label: 'Edit Agreement', icon: CopyIcon },
  { value: 'delete', label: 'Delete Agreement', icon: DeleteIcon },
];

export const draftAddendumOptions = [
  { value: 'view', label: 'View Addendum', icon: ViewExternalLink },
  { value: 'edit', label: 'Edit Addendum', icon: CopyIcon },
  { value: 'deleteAddendum', label: 'Delete Addendum', icon: DeleteIcon },
];

export const contractOptionsWithPaused = [
  { value: 'view', label: 'View Agreement', icon: ViewExternalLink },
];

export const contractOptionsWithActivePendingPaused = [
  { value: 'view', label: 'View Agreement', icon: ViewExternalLink },

  { value: 'pause', label: 'Pause Agreement', icon: PauseIcon },
  { value: 'cancel', label: 'Cancel Agreement', icon: CloseCircleIcon },
];

export const agreementOptions = [
  { key: 'monthly_retainer', label: 'Monthly Retainer' },
  { key: 'rev_share', label: 'Rev Share' },
  { key: 'sales_threshold', label: 'Sales Threshold' },
  { key: 'billing_cap', label: 'Billing Cap' },
  {
    key: 'content_optimization',
    label: 'Copy Optimization',
  },
  {
    key: 'design_optimization',
    label: 'Design Optimization',
  },
  {
    key: 'photography',
    label: 'Photography Optimization',
  },
];
export const channelInvoiceTypeOptions = {
  monthlyRetainer: [
    { label: 'All Retainer Invoices', value: 'all' },
    {
      label: 'Amazon Retainer Invoices',
      value: 'amazon',
    },
    {
      label: 'Walmart Retainer Invoices',
      value: 'walmart',
    },
  ],
  revShare: [
    { label: 'All Rev Share Invoices', value: 'all' },
    {
      label: 'Amazon Rev Share Invoices',
      value: 'amazon',
    },
    {
      label: 'Walmart Rev Share Invoices',
      value: 'walmart',
    },
  ],
  upsell: [
    { label: 'All Upsell Invoices', value: 'all' },
    {
      label: 'Amazon Upsell Invoices',
      value: 'amazon',
    },
    {
      label: 'Walmart Upsell Invoices',
      value: 'walmart',
    },
  ],
};

export const financeTabsOptions = [
  { key: 'retainer', value: 'Monthly Retainer' },
  { key: 'rev share', value: 'Rev Share' },
  { key: 'upsell', value: 'Upsell' },
  { key: 'dsp service', value: 'DSP' },
  { key: 'amc service', value: 'AMC' },
];

export const billingNavigationOptions = [
  'retainer',
  'rev share',
  'upsell',
  'dsp service',
  'amc service',
];

export const adjustInvoiceInputs = [
  {
    key: 1,
    id: 'BT-adjustInvoice-usAmount',
    label: 'US',
    name: 'usAmount',
    inputID: 'usAmount',
    choices: [
      { key: 4, id: 'yes', name: 'choice1', label: 'Yes' },
      { key: 5, id: 'no', name: 'choice1', label: 'No' },
    ],
  },
  {
    key: 2,
    id: 'BT-adjustInvoice-ukAmount',
    label: 'UK',
    name: 'ukAmount',
    inputID: 'ukAmount',
    choices: [
      { key: 6, id: 'yes1', name: 'choice2', label: 'Yes' },
      { key: 7, id: 'no1', name: 'choice2', label: 'No' },
    ],
  },
  {
    key: 3,
    id: 'BT-adjustInvoice-caAmount',
    label: 'Canada',
    name: 'caAmount',
    inputID: 'caAmount',
    choices: [
      { key: 8, id: 'yes2', name: 'choice3', label: 'Yes' },
      { key: 9, id: 'no2', name: 'choice3', label: 'No' },
    ],
  },
];

export const adjustInvoiceChoices = [
  {
    key: 1,
    id: 'BT-adjustInvoice-standard',
    label: 'Standard',
    name: 'standard',
  },
  {
    key: 3,
    id: 'BT-adjustInvoice-onetime',
    label: 'One Time',
    name: 'one time',
  },
];

export const pipelineChoices = [
  { value: 'all_pipeline', label: 'All Pipeline' },
  { value: 'sales_pipeline', label: 'USA Pipeline' },
  { value: 'europe', label: 'Europe Pipeline' },
  { value: 'bbe_store', label: 'BBE Store Pipeline' },
];

export const customerDetailsSideBarNavigationOptions = {
  defaultView: [
    { value: 'performance', label: 'Performance', nav: 'salePerformance' },
    { value: 'inventoryManagement', label: 'Inventory Management' },
    { value: 'goals', label: 'Goals and Strategy', nav: 'goals' },
    { value: 'agreement', label: 'Agreements', nav: 'agreement' },
    { value: 'company-details', label: 'Company Details' },
    { value: 'billing', label: 'Billing', nav: 'billing-details' },
    { value: 'activity', label: 'Activity' },
    { value: 'feedback', label: 'Partner Feedback' },
  ],
  forPlaybookView: [
    { value: 'performance', label: 'Performance', nav: 'salePerformance' },
    { value: 'inventoryManagement', label: 'Inventory Management' },
    { value: 'playbook', label: 'Playbook' },
    { value: 'goals', label: 'Goals and Strategy', nav: 'goals' },
    { value: 'agreement', label: 'Agreements', nav: 'recurring' },
    { value: 'company-details', label: 'Company Details' },
    { value: 'billing', label: 'Billing', nav: 'billing-details' },
    { value: 'activity', label: 'Activity' },
    { value: 'feedback', label: 'Partner Feedback' },
  ],

  customerDefaultView: [
    { value: 'dashboard', label: 'Dashboard' },
    { value: 'performance', label: 'Performance', nav: 'salePerformance' },
    { value: 'inventoryManagement', label: 'Inventory Management' },
    { value: 'goals', label: 'Goals and Strategy', nav: 'goals' },
    { value: 'agreement', label: 'Agreements', nav: 'recurring' },
    { value: 'company-details', label: 'Company Details' },
    { value: 'billing', label: 'Billing', nav: 'billing-details' },
    { value: 'feedback', label: 'Partner Feedback' },
    { value: 'reports', label: 'Reports' },
  ],

  customerDefaultViewWithPlaybook: [
    { value: 'dashboard', label: 'Dashboard' },
    { value: 'performance', label: 'Performance', nav: 'salePerformance' },
    { value: 'inventoryManagement', label: 'Inventory Management' },
    { value: 'goals', label: 'Goals and Strategy', nav: 'goals' },
    { value: 'agreement', label: 'Agreements', nav: 'recurring' },
    { value: 'company-details', label: 'Company Details' },
    { value: 'billing', label: 'Billing', nav: 'billing-details' },
    { value: 'feedback', label: 'Partner Feedback' },
    { value: 'reports', label: 'Reports' },
  ],
};

export const invoiceTypes = ['rev share', 'dsp service', 'retainer', 'upsell'];

export const inventoryTypes = [
  'inventory',
  'overstocked',
  'understocked',
  'standed',
];

export const feedbackOptions = [
  { key: 'difficult', label: '😡' },
  { key: 'hard', label: '😔' },
  { key: 'neutral', label: '😐' },
  { key: 'easy', label: '🙂' },
  { key: 'effortless', label: '😄' },
];

export const requestDemoDetails = [
  {
    key: 'email',
    label: 'Email Address',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Invalid email address.',
    },
  },
  {
    key: 'first_name',
    label: 'First Name',
    pattern: {
      value: /^[a-zA-Z-\s]*$/,
      message: 'Special characters not allowed.',
    },
  },
  {
    key: 'last_name',
    label: 'Last Name',
    pattern: {
      value: /^[a-zA-Z-\s]*$/,
      message: 'Special characters not allowed.',
    },
  },
  { key: 'company_name', label: 'Company' },
];

export const bpChannelTypes = [
  { value: 'all_channels', label: 'All Channels' },
  { value: 'amazon', label: 'Amazon' },
  { value: 'walmart', label: 'Walmart' },
];

export const bpAccountTypes = [
  { value: 'all', label: 'All Accounts' },
  { value: 'parent', label: 'Parent Accounts' },
  { value: 'child', label: 'Child Accounts' },
  { value: 'standalone', label: 'Standalone Accounts' },
];

export const InvoiceIssueOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export const customerStatusNote = {
  inactive:
    "You're about to set this account to Inactive. Please, select the last day of service and add a note on why the account is being inactivated.",

  'at risk':
    'You’re about to set this account to At Risk. The account will remain active, but highlighted as being at risk. Please add a note on why the account is being placed in At Risk status.',

  active:
    'You’re about to set this account to Active. Please add a note on why the account is being placed in active status.',

  'in collections':
    'You’re about to set this account to IN COLLECTIONS. Please add a note on why the account is being placed in IN COLLECTIONS status.',
};

export const customerAgreementTableHeaders = [
  { label: 'Agreement Type', key: 'contract_type', width: '40%' },
  { label: 'Agreement Status', key: 'contract_status', width: '24%' },
  { label: 'Start Date', key: 'start_date', width: '18%' },
  { label: 'Expires in', key: 'end_date', width: '18%' },
];
