// react imports
import React, { Fragment, useCallback } from 'react';

// third party imports
import ReactTooltip from 'react-tooltip';
import { bool, func, string, shape } from 'prop-types';

// local file imports
import DifferenceAdMetric from '../DifferenceAdMetric';
import CardMetrics from '../../../../common/CardMetrics';
import {
  dspMetricsConstants,
  showMetricsTooltipForDSP,
} from '../../../../constants';

const DspMetrics = ({
  setBoxToggle,
  setBoxClasses,
  dspCurrentTotal,
  dspPreviousTotal,
  dspDifference,
  currencySymbol,
  isApiCall,
  dspGoalsMetricData,
  dspGoalsToggle,
}) => {
  const returnTooltipMessge = (key = '') => {
    return `No ${key} available for this period.`;
  };

  const addThousandComma = useCallback((number, decimalDigits = 2) => {
    if (number !== undefined && number !== null) {
      return number
        .toFixed(decimalDigits)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return number;
  }, []);

  // display dsp goals data
  const displayGoalsInfoOnCardMetrics = (metricName) => {
    const target = dspGoalsMetricData?.[metricName]?.target;
    return dspGoalsMetricData?.[metricName] ? (
      <div className="justify-space-between" data-cy="BT-dsp-GoalsData">
        <div className="metric-taget">
          <div className="target-percent-capsule">
            Target{' '}
            {`${
              dspGoalsMetricData?.[metricName]?.sign === 'dollar' ? '$' : ''
            }${dspGoalsMetricData?.[metricName]?.value
              ?.toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              ${
                dspGoalsMetricData?.[metricName]?.sign === 'percentage'
                  ? '%'
                  : ''
              }`}
          </div>
          <div
            className={`${
              target === 'on target' || target === 'on track'
                ? 'on-target'
                : target === 'underspend' || target === 'underspending'
                ? 'underspend'
                : 'under-target'
            } mt-1 spend-status`}
          >
            {target}
          </div>
        </div>
      </div>
    ) : null;
  };

  // display metrics box html
  const displayDspMetricsBoxHTML = (metrics) => {
    const metricsKey = metrics?.metricsKey;
    const classNameKey = metrics?.activeClassName;
    const currentValue = dspCurrentTotal?.[metricsKey];
    const previousValue = dspPreviousTotal?.[metricsKey];
    const differenceValue = dspDifference?.[metricsKey];
    const prefix = metrics?.prefix === 'currency' ? currencySymbol : '';
    const suffix = metrics?.suffix;
    const decimalDigits = metrics?.decimalDigits;
    const hasGoalsToggle = metrics?.hasGoals;
    const differenceType = metrics?.differenceType;

    return (
      <div className={`col-lg-3 col-md-3 col-6 mb-2 ${metrics?.className}`}>
        <CardMetrics
          className="fix-height"
          data-tip={returnTooltipMessge(metrics?.label)}
          data-for={`dspAd${metricsKey}`}
        >
          <div
            id={metrics?.id}
            onClick={() => setBoxToggle(metrics?.key, 'dsp')}
            role="presentation"
            data-cy={metrics?.dataCy}
            className={`${setBoxClasses(metrics?.key, classNameKey, 'dsp')} ${
              !currentValue && !previousValue ? 'disabled' : null
            } ${hasGoalsToggle && dspGoalsToggle ? 'disabled' : ''}`}
          >
            <div className="chart-name">{metrics?.label}</div>
            <div className="number-rate">
              {currentValue
                ? `${prefix}${addThousandComma(
                    currentValue,
                    decimalDigits,
                  )}${suffix}`
                : `${prefix}${decimalDigits === 0 ? '0' : '0.00'}${suffix}`}
            </div>
            <div className="vs">
              {previousValue
                ? `vs ${prefix}${addThousandComma(
                    previousValue,
                    decimalDigits,
                  )}${suffix}`
                : `vs ${prefix}${decimalDigits === 0 ? '0' : '0.00'}${suffix}`}
            </div>
            <div className="justify-space-between">
              {differenceValue || differenceValue === 0 ? (
                <DifferenceAdMetric
                  value={differenceValue}
                  type={differenceType}
                />
              ) : (
                <div className="perentage-value down mt-3 pt-1">N/A</div>
              )}
              {displayGoalsInfoOnCardMetrics(metrics?.metricsKey)}
            </div>
          </div>
        </CardMetrics>
      </div>
    );
  };

  return (
    <>
      {showMetricsTooltipForDSP?.map((key) => {
        return (
          <Fragment key={key}>
            {!dspCurrentTotal[key] && !dspPreviousTotal[key] && !isApiCall ? (
              <ReactTooltip
                id={`dspAd${key}`}
                class="initialTextTransform"
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />
            ) : null}
          </Fragment>
        );
      })}
      <div className="row mr-1 ml-1">
        {displayDspMetricsBoxHTML(dspMetricsConstants?.dspImpressions)}
        {displayDspMetricsBoxHTML(dspMetricsConstants?.dspSpend)}
        {displayDspMetricsBoxHTML(dspMetricsConstants?.dspTotalProductSales)}
        {displayDspMetricsBoxHTML(dspMetricsConstants?.dspTotalRoas)}
        {displayDspMetricsBoxHTML(dspMetricsConstants?.dspTotalDpvr)}
        {displayDspMetricsBoxHTML(dspMetricsConstants?.dspTtlNewBrandPurchases)}
        {displayDspMetricsBoxHTML(dspMetricsConstants?.dspProductSales)}
        {displayDspMetricsBoxHTML(dspMetricsConstants?.dspRoas)}
      </div>
    </>
  );
};

export default DspMetrics;

DspMetrics.defaultProps = {
  currencySymbol: '$',
  isApiCall: false,
  dspGoalsToggle: false,
  dspCurrentTotal: {},
  dspPreviousTotal: {},
  dspDifference: {},
  dspGoalsMetricData: {},
};

DspMetrics.propTypes = {
  isApiCall: bool,
  dspGoalsToggle: bool,
  currencySymbol: string,
  dspGoalsMetricData: shape({}),
  dspCurrentTotal: shape({}),
  dspPreviousTotal: shape({}),
  dspDifference: shape({}),
  setBoxClasses: func.isRequired,
  setBoxToggle: func.isRequired,
};
