import React, { Fragment } from 'react';

import { bool, func, shape } from 'prop-types';

import { NoData, PageLoader, Table } from '../../../../../common';
import useFormatNumber from '../../../../../hooks/useFormatNumber';
import { ArrowDownIcon, ArrowUpIcon } from '../../../../../theme/images';
import Theme from '../../../../../theme/Theme';
import RestockMobileView from './RestockMobileView';
import {
  summaryRestockLimitHeader,
  summaryRestockLimitStorageType,
} from '../../../../../constants';

const SummaryRestockLimits = ({
  restockData,
  restockLoader,
  isDesktop,
  formatNumber,
  selectedTimeFrame,
}) => {
  const renderTableHeader = () => {
    return (
      <>
        <tr>
          {summaryRestockLimitHeader.map((item) => {
            return (
              <th key={item.key} width={item.width} className="product-header">
                {item.label}
              </th>
            );
          })}
        </tr>
      </>
    );
  };

  const renderInventoryData = (item) => {
    return (
      <>
        <tr key={item.customer_id}>
          <td className="small-label-text">{item.label}</td>

          <td className="product-body">
            <span className="d-inline-block w-50">
              {formatNumber(
                restockData?.storage[item.key]?.utilization,
                '',
                '%',
              )}
            </span>
            {selectedTimeFrame?.value === 'custom' ||
            restockData?.storage[item.key]?.utilization_change === null ? (
              'N/A'
            ) : restockData?.storage[item.key]?.utilization_change === 0 ? (
              <span className="increase-rate gray80 large  d-inline-block">
                {formatNumber(
                  restockData?.storage[item.key]?.utilization_change,
                  '',
                  '%',
                )}
              </span>
            ) : restockData?.storage[item.key]?.utilization_change > 0 ? (
              <span className="increase-rate large d-inline-block">
                <img className="green-arrow" src={ArrowUpIcon} alt="arrow-up" />
                {formatNumber(
                  restockData?.storage[item.key]?.utilization_change,
                  '',
                  '%',
                )}
              </span>
            ) : (
              <span className="decrease-rate large d-inline-block">
                <img className="red-arrow" src={ArrowDownIcon} alt="arrow-up" />
                {formatNumber(
                  Math.abs(restockData?.storage[item.key]?.utilization_change),
                  '',
                  '%',
                )}
              </span>
            )}
          </td>

          <td className="product-body">
            <span className="d-inline-block w-50">
              {' '}
              {formatNumber(
                restockData?.storage[item.key]?.max_shipment,
                '',
                '',
                true,
              )}
            </span>
            {selectedTimeFrame?.value === 'custom' ||
            restockData?.storage[item.key]?.max_shipment_change === null ? (
              'N/A'
            ) : restockData?.storage[item.key]?.max_shipment_change === 0 ? (
              <span className="increase-rate gray80 large  d-inline-block">
                {formatNumber(
                  restockData?.storage[item.key]?.max_shipment_change,
                  '',
                  '%',
                )}
              </span>
            ) : restockData?.storage[item.key]?.max_shipment_change > 0 ? (
              <span className="increase-rate large d-inline-block">
                <img className="green-arrow" src={ArrowUpIcon} alt="arrow-up" />
                {formatNumber(
                  restockData?.storage[item.key]?.max_shipment_change,
                  '',
                  '%',
                )}
              </span>
            ) : (
              <span className="decrease-rate large d-inline-block">
                <img className="red-arrow" src={ArrowDownIcon} alt="arrow-up" />
                {formatNumber(
                  Math.abs(restockData?.storage[item.key]?.max_shipment_change),
                  '',
                  '%',
                )}
              </span>
            )}
          </td>

          <td className="product-body ">
            <span className="d-inline-block w-50">
              {formatNumber(
                restockData?.storage[item.key]?.open_shipment,
                '',
                '',
                true,
              )}{' '}
            </span>
            {selectedTimeFrame?.value === 'custom' ||
            restockData?.storage[item.key]?.open_shipment_change === null ? (
              'N/A'
            ) : restockData?.storage[item.key]?.open_shipment_change === 0 ? (
              <span className="increase-rate gray80 large  d-inline-block">
                {formatNumber(
                  restockData?.storage[item.key]?.open_shipment_change,
                  '',
                  '%',
                )}
              </span>
            ) : restockData?.storage[item.key]?.open_shipment_change > 0 ? (
              <span className="increase-rate large d-inline-block">
                <img className="green-arrow" src={ArrowUpIcon} alt="arrow-up" />
                {formatNumber(
                  restockData?.storage[item.key]?.open_shipment_change,
                  '',
                  '%',
                )}
              </span>
            ) : (
              <span className="decrease-rate large  d-inline-block">
                <img className="red-arrow" src={ArrowDownIcon} alt="arrow-up" />
                {formatNumber(
                  Math.abs(
                    restockData?.storage[item.key]?.open_shipment_change,
                  ),
                  '',
                  '%',
                )}
              </span>
            )}
          </td>

          <td className="product-body ">
            <span className="d-inline-block w-50">
              {formatNumber(
                restockData?.storage[item.key]?.on_hand,
                '',
                '',
                true,
              )}{' '}
            </span>
            {selectedTimeFrame?.value === 'custom' ||
            restockData?.storage[item.key]?.on_hand_change === null ? (
              'N/A'
            ) : restockData?.storage[item.key]?.on_hand_change === 0 ? (
              <span className="increase-rate gray80 large  d-inline-block">
                {formatNumber(
                  restockData?.storage[item.key]?.on_hand_change,
                  '',
                  '%',
                )}
              </span>
            ) : restockData?.storage[item.key]?.on_hand_change > 0 ? (
              <span className="increase-rate large d-inline-block">
                <img className="green-arrow" src={ArrowUpIcon} alt="arrow-up" />
                {formatNumber(
                  restockData?.storage[item.key]?.on_hand_change,
                  '',
                  '%',
                )}
              </span>
            ) : (
              <span className="decrease-rate large  d-inline-block">
                <img className="red-arrow" src={ArrowDownIcon} alt="arrow-up" />
                {formatNumber(
                  Math.abs(restockData?.storage[item.key]?.on_hand_change),
                  '',
                  '%',
                )}
              </span>
            )}
          </td>
        </tr>
      </>
    );
  };

  return (
    <div className="mt-4">
      {isDesktop ? (
        <Table className="mt-0">
          <thead>{renderTableHeader()}</thead>

          {restockData && Object.keys(restockData).length !== 0
            ? summaryRestockLimitStorageType.map((item) => {
                return (
                  <tbody key={item?.key}>{renderInventoryData(item)}</tbody>
                );
              })
            : null}
        </Table>
      ) : (
        <>
          {restockData && Object.keys(restockData).length !== 0
            ? summaryRestockLimitStorageType.map((item) => {
                return (
                  <Fragment key={item.key}>
                    <RestockMobileView
                      label={item.label}
                      formatNumber={useFormatNumber}
                      utilization={restockData?.storage[item.key]?.utilization}
                      utlPercent={
                        restockData?.storage[item.key]?.utilization_change
                      }
                      maxShip={restockData?.storage[item.key]?.max_shipment}
                      maxShipPercent={
                        restockData?.storage[item.key]?.max_shipment_change
                      }
                      openShip={restockData?.storage[item.key]?.open_shipment}
                      openShipPercent={
                        restockData?.storage[item.key]?.open_shipment_change
                      }
                      onhand={restockData?.storage[item.key]?.on_hand}
                      onhandPercent={
                        restockData?.storage[item.key]?.on_hand_change
                      }
                    />
                  </Fragment>
                );
              })
            : null}
        </>
      )}

      {restockLoader ? (
        <PageLoader
          component="performance-graph"
          type="detail"
          color={Theme.orange}
          width={40}
          height={40}
        />
      ) : null}

      {Object.keys(restockData).length === 0 &&
      restockData.constructor === Object &&
      !restockLoader ? (
        <NoData>No Restock Limits Data Found</NoData>
      ) : null}
    </div>
  );
};

export default SummaryRestockLimits;

SummaryRestockLimits.defaultProps = {
  restockData: {},
};

SummaryRestockLimits.propTypes = {
  restockLoader: bool.isRequired,
  isDesktop: bool.isRequired,
  restockData: shape({}),
  formatNumber: func.isRequired,
  selectedTimeFrame: shape({}).isRequired,
};
