import styled from 'styled-components';
import Theme from '../../theme/Theme';

export const GreenCheckBox = styled.div`
  display: block;
  position: relative;
  padding-left: 35px;
  padding-bottom: 15px;
  font-size: ${Theme.extraNormal};
  color: ${Theme.black};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600;
  border-bottom: 1px dotted ${Theme.gray25};

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .steps-completed {
    color: ${Theme.lighterGreen};
    font-weight: 500;

    &.gray-color {
      color: ${Theme.gray40};
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    border: 1px solid ${Theme.gray35};
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  input:checked ~ .checkmark {
    background-color: #e5f1e5;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 9px;
    top: 4px;
    width: 5px;
    height: 12px;
    border: solid ${Theme.lighterGreen};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const ActiveAgreementTable = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  ${Theme.extraNormal};
  color: ${Theme.black};

  table {
    width: 100%;

    tr {
      text-align: left;
      background: ${Theme.white};
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 1px;
      font-family: ${Theme.baseFontFamily};
      width: 100%;

      th {
        padding: 5px 0px 12px 0px;
        position: relative;
        vertical-align: top;
        border-bottom: 1px solid #e2e2ea;
      }

      td {
        padding: 12px 0;

        /* .sign-box {
           background-color: #ffffff;
           border: 1px solid #d5d8e1;
           border-radius: 2px;
           max-width: 100px;
           height: 32px;
           text-align: center;
           padding: 6px;
         }
         .solid-icon {
           width: 36px;
           height: 36px;
           margin-right: 10px;
         }
         .recurring-service {
           font-size: 14px;
         } */
      }
    }
  }
  .solid-icon {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
  .recurring-service {
    font-size: ${Theme.extraNormal};
  }
  .sign-box {
    background-color: ${Theme.white};
    border: 1px solid ${Theme.gray45};
    border-radius: 2px;
    max-width: 100px;
    height: 32px;
    text-align: center;
    padding: 6px;
  }
`;
