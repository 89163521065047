// react imports
import React, { useState } from 'react';

// third party imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, shape, func } from 'prop-types';

// local file imports
import { NoRecordFound, CollapseTableMobileView } from '../../../../../common';

function AMCAudienceSummaryMobileView({
  amcAudienceSummaryData,
  AMCAudienceSummaryHeaders,
  displayValue,
}) {
  const [viewMoreDetailsFlag, setViewMoreDetailsFlag] = useState({
    index: null,
    flag: null,
  });

  // display amc audience capsule data
  const displayamcAudienceCapsuleData = (
    dspAudienceItem,
    dspAudienceItemIndex,
  ) => {
    if (
      viewMoreDetailsFlag?.index === dspAudienceItemIndex &&
      viewMoreDetailsFlag?.flag
    ) {
      return AMCAudienceSummaryHeaders?.map((audienceHeader) => {
        return (
          <div className="col-6 mb-3" key={audienceHeader?.label}>
            <div className="label mb-0" id={audienceHeader?.key}>
              {audienceHeader?.label}
            </div>
            <div className="normal-text-black text-medium">
              {displayValue(dspAudienceItem, audienceHeader)}
            </div>
          </div>
        );
      });
    }
    return AMCAudienceSummaryHeaders.slice(0, 4)?.map((audienceHeader) => {
      return (
        <div className="col-6 mb-3" key={audienceHeader?.label}>
          <div className="label mb-0" id={audienceHeader?.key}>
            {audienceHeader?.label}
          </div>
          <div className="normal-text-black text-medium">
            {displayValue(dspAudienceItem, audienceHeader)}
          </div>
        </div>
      );
    });
  };

  // display amc audience mobile view
  const displayAmcAudienceMobileView = () => {
    return (
      <>
        {amcAudienceSummaryData?.length ? (
          amcAudienceSummaryData?.map(
            (dspAudienceItem, dspAudienceItemIndex) => {
              return (
                <fieldset className="shape-without-border w-430 mt-2 mb-4">
                  <div className="row" id={dspAudienceItem.id}>
                    {displayamcAudienceCapsuleData(
                      dspAudienceItem,
                      dspAudienceItemIndex,
                    )}

                    <div
                      className="capsule-collpase"
                      role="presentation"
                      onClick={() => {
                        setViewMoreDetailsFlag({
                          index: dspAudienceItemIndex,
                          flag: !viewMoreDetailsFlag.flag,
                        });
                      }}
                    >
                      {viewMoreDetailsFlag?.index === dspAudienceItemIndex &&
                      viewMoreDetailsFlag?.flag
                        ? 'View less details'
                        : `View 8 more details`}
                      <FontAwesomeIcon
                        icon="fa-regular fa-angle-down"
                        className="dropdown-arrow-icon"
                        style={{
                          transform:
                            viewMoreDetailsFlag?.index ===
                              dspAudienceItemIndex && viewMoreDetailsFlag?.flag
                              ? 'rotate(180deg)'
                              : '',
                          marginLeft: '10px',
                          verticalAlign: 'middle',
                        }}
                      />
                    </div>
                  </div>
                </fieldset>
              );
            },
          )
        ) : (
          <div className="row">
            <NoRecordFound
              type="DSP-audience"
              title="We looked high and low, but…"
              subTitle="No records found."
            />
          </div>
        )}
      </>
    );
  };

  /* ********** Main return statement of this component ********** */
  return (
    <CollapseTableMobileView>
      {displayAmcAudienceMobileView()}
    </CollapseTableMobileView>
  );
}

export default AMCAudienceSummaryMobileView;

AMCAudienceSummaryMobileView.defaultProps = {
  amcAudienceSummaryData: [],
  AMCAudienceSummaryHeaders: [],
  displayValue: () => {},
};

AMCAudienceSummaryMobileView.propTypes = {
  amcAudienceSummaryData: arrayOf(shape({})),
  AMCAudienceSummaryHeaders: arrayOf(shape({})),
  displayValue: func,
};
