import styled from 'styled-components';

import Theme from '../../../../theme/Theme';

const BreakDown = styled.div`
  .dropdown-revenue-input {
    border-radius: 6px;
    position: relative;
    border: 1px solid ${Theme.gray45};
    width: 75%;
    min-width: 93px;
    text-align: left;
    font-size: ${Theme.extraNormal};
    border-radius: 6px;
    min-height: 32px;
    color: ${Theme.gray85};
    padding: 7px;
    cursor: pointer;

    span.selected-list {
      position: fixed;
      color: ${Theme.black};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 65%;
      margin-left: 4px;
      position: absolute;
    }

    .dropdown-arrow-icon {
      position: absolute;
      right: 9px;
      top: 9px;
    }
  }
  .dropdown-revenue-filter {
    background-color: ${Theme.white};
    border-radius: 8px;
    box-shadow: 0 5px 15px 0 rgba(68, 68, 79, 0.4);
    padding: 0 14px;
    position: absolute;
    z-index: 99999;
    top: 35px;
    color: ${Theme.gray85};
    text-align: left;
    top: 37px;
    right: 15px;
    width: 100%;

    &.hide {
      display: none;
    }
    &.show {
      display: block;
    }

    .options-list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: block;
        padding: 0;
      }
    }
  }
`;

export default BreakDown;
