import React from 'react';

import { shape } from 'prop-types';

import { WhiteCard } from '../../../../../common';

const PositiveFeedback = ({ dspData }) => {
  const displaySellerHealth = (value) => {
    return (
      <div className="seller-health positive">
        {value && value !== 'nan' ? `${value}%` : 'N/A'}
      </div>
    );
  };

  return (
    <div className="col-md-3 col-sm-12 mb-3">
      <WhiteCard className="fix-height">
        <p className="black-heading-title mt-0 mb-4">Positive Feedback</p>
        {displaySellerHealth(dspData?.feedback_30)}
        <div className="seller-update mb-3">Last 30 days</div>
        {displaySellerHealth(dspData?.feedback_365)}
        <div className="seller-update mb-5">Last 12 months</div>
        <div className="last-update ">Last updated: {dspData?.latest_date}</div>
      </WhiteCard>
    </div>
  );
};

export default PositiveFeedback;

PositiveFeedback.defaultProps = { dspData: null };

PositiveFeedback.propTypes = {
  dspData: shape({}),
};
