import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, shape, func } from 'prop-types';

import { CollapseTableMobileView } from '../../../../../common';

function AMCSelfServiceMobileView({
  tableHeader,
  tableData,
  displayValue,
  displayInputField,
}) {
  const [viewMoreDetailsFlag, setViewMoreDetailsFlag] = useState({
    index: null,
    flag: null,
  });

  const displayTableData = (item, tableDataIndex) => {
    if (
      viewMoreDetailsFlag?.index === tableDataIndex &&
      viewMoreDetailsFlag?.flag
    ) {
      return tableHeader?.map((header) => {
        return (
          <div className="col-6 mb-3" key={header?.label}>
            <div className="label mb-0" id={header?.key}>
              {header?.label}
            </div>
            <div className="normal-text-black text-medium">
              {item?.id
                ? displayValue(item, header)
                : displayInputField(item, header, tableDataIndex)}
            </div>
          </div>
        );
      });
    }

    return tableHeader.slice(0, 4)?.map((header) => {
      return (
        <div className="col-6 mb-3" key={header?.label}>
          <div className="label mb-0" id={header?.key}>
            {header?.label}
          </div>
          <div className="normal-text-black text-medium">
            {item?.id
              ? displayValue(item, header)
              : displayInputField(item, header, tableDataIndex)}
          </div>
        </div>
      );
    });
  };

  const displayTable = () => {
    return (
      <>
        {tableData?.map((item, tableDataIndex) => {
          const rowKey = item?.type
            ? `${item.type}-${(tableDataIndex + 1)?.toString()}`
            : `${item?.id || ''}${item?.instance_id || ''}`;
          return (
            <fieldset className="shape-without-border w-430 mt-2 mb-4">
              <div className="row" id={rowKey}>
                {displayTableData(item, tableDataIndex)}

                <div
                  className="capsule-collpase"
                  role="presentation"
                  onClick={() => {
                    setViewMoreDetailsFlag({
                      index: tableDataIndex,
                      flag: !viewMoreDetailsFlag.flag,
                    });
                  }}
                >
                  {viewMoreDetailsFlag?.index === tableDataIndex &&
                  viewMoreDetailsFlag?.flag
                    ? 'View less details'
                    : `View more details`}
                  <FontAwesomeIcon
                    icon="fa-regular fa-angle-down"
                    className="dropdown-arrow-icon"
                    style={{
                      transform:
                        viewMoreDetailsFlag?.index === tableDataIndex &&
                        viewMoreDetailsFlag?.flag
                          ? 'rotate(180deg)'
                          : '',
                      marginLeft: '10px',
                      verticalAlign: 'middle',
                    }}
                  />
                </div>
              </div>
            </fieldset>
          );
        })}
      </>
    );
  };

  return <CollapseTableMobileView>{displayTable()}</CollapseTableMobileView>;
}

export default AMCSelfServiceMobileView;

AMCSelfServiceMobileView.defaultProps = {
  tableHeader: [],
  tableData: [],
  displayValue: () => {},
  displayInputField: () => {},
};

AMCSelfServiceMobileView.propTypes = {
  tableHeader: arrayOf(shape({})),
  tableData: arrayOf(shape({})),
  displayValue: func,
  displayInputField: func,
};
