import React from 'react';

import { shape } from 'prop-types';

import { WhiteCard } from '../../../../../common';

const OrderIssuesCard = ({ dspData }) => {
  const displaySellerHealth = (value) => {
    return (
      <div className="seller-health">
        {value && value !== 'nan' ? `${value}%` : 'N/A'}
      </div>
    );
  };

  return (
    <div className="col-md-3 col-sm-12 mb-3">
      <WhiteCard className="fix-height">
        <p className="black-heading-title mt-0 mb-4">Order Issues</p>
        {displaySellerHealth(dspData?.order_defect_fba)}
        <div className="seller-update mb-3">Order Defect Rate</div>
        {displaySellerHealth(dspData?.policy_issues)}
        <div className="seller-update mb-5">Policy Violations</div>
        <div className="last-update ">Last updated: {dspData?.latest_date}</div>
      </WhiteCard>
    </div>
  );
};

export default OrderIssuesCard;

OrderIssuesCard.defaultProps = { dspData: null };

OrderIssuesCard.propTypes = {
  dspData: shape({}),
};
