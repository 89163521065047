import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, shape, bool, func } from 'prop-types';

import { WhiteCard, CollapseTableMobileView } from '../../../../../common';

function COSMobileView({
  cosData,
  asinsList,
  stickyColumns,
  tableColumns,
  isEditFlagOn,
  displayInputBox,
  displayValues,
  selectedViewType,
  cosAccessOfBP,
  displayDownloadBtn,
  isSingleMonthSelected,
  displayAddNewRowBtn,
}) {
  const [viewMoreDetailsFlag, setViewMoreDetailsFlag] = useState({
    index: null,
    flag: null,
  });
  const totalColumns = stickyColumns.concat(tableColumns);

  const displayTableData = (cos, cosIndex) => {
    if (viewMoreDetailsFlag?.index === cosIndex && viewMoreDetailsFlag?.flag) {
      return totalColumns?.map((item) => {
        return (
          <div className="col-6 mb-3" key={item?.label}>
            <div className="label mb-0" id={item?.key}>
              {item?.label}
            </div>
            <div className="normal-text-black text-medium">
              {isEditFlagOn
                ? displayInputBox(item, cos, cosIndex)
                : displayValues(item, cos, cosIndex)}
            </div>
          </div>
        );
      });
    }
    return totalColumns.slice(0, 4)?.map((item) => {
      return (
        <div className="col-6 mb-3" key={item?.label}>
          <div className="label mb-0" id={item?.key}>
            {item?.label}
          </div>
          <div className="normal-text-black text-medium">
            {isEditFlagOn
              ? displayInputBox(item, cos, cosIndex)
              : displayValues(item, cos, cosIndex)}
          </div>
        </div>
      );
    });
  };

  const displayStickyTable = () => {
    return (
      <>
        {cosData?.length && asinsList?.length ? (
          cosData?.length ? (
            asinsList?.map((cos, cosIndex) => {
              return (
                <fieldset className="shape-without-border w-430 mt-2 mb-4">
                  <div className="row" id={cosData.id}>
                    {displayTableData(cos, cosIndex)}
                    <div
                      className="capsule-collpase"
                      role="presentation"
                      onClick={() => {
                        setViewMoreDetailsFlag({
                          index: cosIndex,
                          flag: !viewMoreDetailsFlag.flag,
                        });
                      }}
                    >
                      {viewMoreDetailsFlag?.index === cosIndex &&
                      viewMoreDetailsFlag?.flag
                        ? 'View less details'
                        : `View 29 more details`}
                      <FontAwesomeIcon
                        icon="fa-regular fa-angle-down"
                        className="dropdown-arrow-icon"
                        style={{
                          transform:
                            viewMoreDetailsFlag?.index === cosIndex &&
                            viewMoreDetailsFlag?.flag
                              ? 'rotate(180deg)'
                              : '',
                          marginLeft: '10px',
                          verticalAlign: 'middle',
                        }}
                      />
                    </div>
                  </div>
                </fieldset>
              );
            })
          ) : (
            <tr>No Data Available</tr>
          )
        ) : (
          <p className=" gray-medium-text my-2 text-center">No COS Data</p>
        )}
      </>
    );
  };

  return (
    <CollapseTableMobileView>
      <WhiteCard className="justify-space-between mb-1">
        <div className="COS-view-label">{selectedViewType?.label}</div>
        {cosData?.length && cosAccessOfBP?.show ? displayDownloadBtn() : ''}
      </WhiteCard>
      {displayStickyTable()}
      {cosData?.length &&
      isEditFlagOn &&
      cosAccessOfBP?.show &&
      isSingleMonthSelected()
        ? displayAddNewRowBtn()
        : null}
    </CollapseTableMobileView>
  );
}

export default COSMobileView;

COSMobileView.defaultProps = {
  cosData: [],
  asinsList: [],
  stickyColumns: [],
  tableColumns: [],
  isEditFlagOn: false,
  displayInputBox: () => {},
  displayValues: () => {},
  selectedViewType: {},
  cosAccessOfBP: {},
  displayDownloadBtn: () => {},
  isSingleMonthSelected: () => {},
  displayAddNewRowBtn: () => {},
};

COSMobileView.propTypes = {
  cosData: arrayOf(shape({})),
  asinsList: arrayOf(shape({})),
  stickyColumns: arrayOf(shape({})),
  tableColumns: arrayOf(shape({})),
  isEditFlagOn: bool,
  displayInputBox: func,
  displayValues: func,
  selectedViewType: shape({}),
  cosAccessOfBP: shape({}),
  displayDownloadBtn: func,
  isSingleMonthSelected: func,
  displayAddNewRowBtn: func,
};
