import React from 'react';

import getSymbolFromCurrency from 'currency-symbol-map';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shape } from 'prop-types';

import Theme from '../../../../../theme/Theme';
import useFormatNumber from '../../../../../hooks/useFormatNumber';
import { WhiteCard } from '../../../../../common';
import { PATH_CUSTOMER_DETAILS } from '../../../../../constants';
import { setCustomerSelectedTab } from '../../../../../store/actions/customerState';

const SummaryInvoicesCard = ({ invoiceData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customerState.data);
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const isSuperAdmin = userInfo?.role?.includes('Super Admin');

  return (
    <div className={!isSuperAdmin ? 'col-12' : 'col-md-3 col-sm-12 mb-3'}>
      <WhiteCard className="fix-height">
        <div className="mt-0 mb-4 ">
          <span
            className="black-heading-title cursor"
            onClick={() => {
              dispatch(setCustomerSelectedTab('billing'));
              history.push(
                PATH_CUSTOMER_DETAILS.replace(':id', customer?.id),
                'retainer',
              );
            }}
            aria-hidden="true"
          >
            Invoices{' '}
            <FontAwesomeIcon
              icon="fa-light fa-square-arrow-up-right"
              style={{
                fontSize: '16px',
                color: Theme.orange,
                marginLeft: '8px',
              }}
            />
          </span>
        </div>
        <div className="invoicesAmount">
          {useFormatNumber(
            invoiceData?.total_outstanding ? invoiceData?.total_outstanding : 0,
            getSymbolFromCurrency(invoiceData?.local_currency),
            '',
            true,
          )}
        </div>
        <div className="seller-update mb-3">
          Total Outstanding ({invoiceData?.total_outstanding_cnt} Invoices)
        </div>
        <div
          style={{ color: Theme.orange }}
          className="invoicesAmount positive "
        >
          {useFormatNumber(
            invoiceData?.amount_overdue ? invoiceData?.amount_overdue : 0,
            getSymbolFromCurrency(invoiceData?.local_currency),
            '',
            true,
          )}
        </div>
        <div className="seller-update mb-5">
          Amount Overdue ({invoiceData?.amount_overdue_cnt} Invoices)
        </div>
      </WhiteCard>
    </div>
  );
};

export default SummaryInvoicesCard;

SummaryInvoicesCard.defaultProps = { invoiceData: null };

SummaryInvoicesCard.propTypes = {
  invoiceData: shape({}),
};
