import styled from 'styled-components';
import Theme from '../theme/Theme';

const CollapseTableMobileView = styled.div`
  .shape-without-border {
    position: relative;
  }
  .capsule-collpase {
    border-radius: 20px;
    bottom: 0;
    border: 1px solid ${Theme.gray11};
    color: ${Theme.black};
    font-size: ${Theme.extraNormal};
    font-family: Noah-Medium;
    background: ${Theme.white};
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px 3px 12px;
    text-align: center;
    position: absolute;
    margin: -18px auto;
    right: 0;
    left: 0;
  }
  .asin-trash {
    padding-right: 5px;
  }

  .display-flex {
    display: flex;
  }
`;

export default CollapseTableMobileView;
