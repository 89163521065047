/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';

import $ from 'jquery';
import dayjs from 'dayjs';
import Select from 'react-select';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Activity from './Activity';
import Theme from '../../../theme/Theme';
import CompanyDetail from './CompanyDetail';
import AccountDetails from './AccountDetails';
import ShowTeamMembers from './ShowTeamMembers';
import CustomerTabDetails from './CustomerTabDetails';
import PlaybookContainer from './Playbook/PlaybookContainer';
import BillingContainer from './BillingContainer/BillingContainer';
import SummaryViewContainer from './SummaryView/SummaryViewContainer';
import GoalsAndCosContainer from './GoalsAndCOS/GoalsAndCosContainer';
import CustomerFeedbackList from './CustomerFeedback/CustomerFeedbackList';
import AgreementDetailContainer from './Agreement/AgreementDetailContainer';
import CompanyPerformance from './CompanyPerformance/CompanyPerformanceContainer';
import ReportsSectionContainer from '../../ReportsSection/ReportsSectionContainer';
import InventoryManagementContainer from './InventoryManagement/InventoryManagementContainer';
import { dynamicErrorMsg } from '../../../store/actions/userState';
import { SetupCheckList } from '../../BrandAssetGathering/index';
import { LeftArrowIcon } from '../../../theme/images';
import { userMe } from '../../../store/actions';
import { ProductCatalog } from '../index';
import {
  CustomerDetailsBody,
  CustomerTabCollapse,
  CustomerTabList,
} from './CustomerDetailStyles';
import {
  getCustomerDetails,
  setCustomerSelectedTab,
  setCustomerMembers,
} from '../../../store/actions/customerState';
import {
  TeamMemberModal,
  CustomerStatusModal,
  OtherModals,
  NotesModal,
} from './Modals';
import {
  PageLoader,
  PageNotFound,
  BackToTop,
  WhiteCard,
} from '../../../common';
import {
  getCustomerMembers,
  saveSPData,
  getPlaybookServicesListData,
  getSPData,
  getOnboardingPlaybookData,
  getChildBps,
  getPlaybookTeamData,
  getMarketPlaceList,
  getBPsAllMArketplaceList,
  getGoalsAccountTypeFlag,
} from '../../../api';
import {
  billingNavigationOptions,
  customerDetailsSideBarNavigationOptions as sideBarMenu,
  inventoryTypes,
  invoiceTypes,
  PATH_CUSTOMER_DETAILS,
  PATH_CUSTOMER_LIST,
  SALEPERFORMANCE,
  ADPERFORMANCE,
  CREATIVEPROJECTS,
  HYBRID,
  SELLER,
  AMCINSIGHTS,
  PARENT,
  VENDORSMALL,
  SELLERSMALL,
  DSPPERFORMANCE,
} from '../../../constants';

export default function CustomerMainContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const params = queryString.parse(history.location.search);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const agreement = useSelector((state) => state?.accountState?.data);
  const customerState = useSelector((state) => state?.customerState);
  const customerLoader = customerState?.isLoading;
  const customer = customerState?.data;
  const customerName = customer?.company_name;
  const customerStatus = customer?.status?.value;
  const customerAccountType = customer?.customer_account_type;
  const customerSelectedTab = customerState?.customerSelectedTab;
  const [onScrollPage, setOnScrollPage] = useState(false);
  const [sidePanelToggle, setSidePanelToggle] = useState({
    isToggle: false,
    width: '75px',
  });
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [isSaveData, IsSaveDataClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showMemberList, setShowMemberList] = useState({
    show: false,
    add: false,
    modal: false,
  });
  const [allMarketplacesList, setAllMarketplacesList] = useState([]);
  const [marketplaceChoices, setMarketplaceChoices] = useState([]);
  const [marketplacesList, setMarketplacesList] = useState([]);
  const [originalMarketplaceList, setOriginalMarketplacesList] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [isUserAssignedToBP, setIsUserAssignedToBP] = useState(false);
  const [showNewNoteEditor, setNewNoteEditor] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState({
    modal: false,
    apiCall: false,
    deleteNote: false,
  });
  const [statusModal, setStatusModal] = useState({
    show: false,
    type: '',
  });
  const customerError = useSelector((state) => state.customerState.error);
  const userInfo = useSelector((state) => state.userState.userInfo);
  const isCustomer = userInfo?.role?.includes('Customer');
  const userId = userInfo?.id;

  const [agreementDetailModal, setAgreementDetailModal] = useState({
    pause: false,
  });
  const [isAuthorized, setIsAuthorized] = useState({});
  const [childBPList, setChildBPList] = useState([]);
  const [childBPAllList, setChildBPAllList] = useState([]);

  const isCustomerActive = customerStatus !== 'pending';
  const isBBEInternalUser = userInfo?.isInternalUser ? 'internal' : 'external';
  const [showCreativeUploadScreen, setShowCreativeUploadScreen] =
    useState(false);
  const [goalsAccountTypeFlag, setGoalsAccountTypeFlag] = useState({
    seller: true,
    vendor: false,
    hybrid: true,
  });

  // needed for playbook
  const currentDate = new Date();
  const currentYear = dayjs(currentDate).format('YYYY');
  const playbookEpicId = customer?.playbook_epic_id;
  const playbookAccess = userInfo?.main_playbook_access;
  const onboardingPlaybookAccess = userInfo?.onboarding_playbook_access;
  const [showPlaybook, setShowPlaybook] = useState(false);
  const [playbookTeamsList, setPlaybookTeamsList] = useState([]);
  const [playbookServiceList, setPlaybookServiceList] = useState([]);
  const [playbookTabActive, setPlaybookTabActive] = useState('playbook');

  let viewOptions = [
    { value: 'performance', label: 'Performance' },
    { value: 'playbook', label: 'Playbook' },
    { value: 'goals', label: 'Goals and Strategy', nav: 'goals' },
    { value: 'inventoryManagement', label: 'Inventory Management' },
    { value: 'agreement', label: 'Agreements' },
    { value: 'company-details', label: 'Company Details' },
    { value: 'activity', label: 'Activity' },
    { value: 'feedback', label: 'Partner Feedback' },
  ];

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '600px ',
      width: '100% ',
      minHeight: userInfo?.role?.includes('Customer') ? '130px' : '200px',
      overlay: ' {zIndex: 1000}',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  // add event listener when windows scroll down by some 'px'
  const windowsScrollEvents = useCallback(() => {
    const scrollTop = window.scrollY;

    if (scrollTop >= 200) {
      setOnScrollPage(true);
    } else {
      setOnScrollPage(false);
    }
  }, []);

  // use effect for windows scrollable events
  useEffect(() => {
    window.addEventListener('scroll', windowsScrollEvents);
    return () => {
      window.removeEventListener('scroll', windowsScrollEvents);
    };
  }, [windowsScrollEvents]);

  const bindChildBPsList = useCallback((responseData) => {
    const tempChildBPList = [];

    if (responseData?.length > 0) {
      const filteredResponseData = responseData?.filter(
        (item) => item?.status !== 'inactive',
      );

      if (filteredResponseData?.length > 0) {
        filteredResponseData.forEach((item) => {
          tempChildBPList.push({
            account_type: item?.account_type,
            bgs: item?.bgs,
            label: item?.label,
            status: item?.status,
            value: item?.value,
          });
        });
      }
    }

    return tempChildBPList;
  }, []);

  const getChildBpList = useCallback(() => {
    getChildBps(id).then((response) => {
      setChildBPList(bindChildBPsList(response?.data));
      setChildBPAllList(response?.data);
    });
  }, [bindChildBPsList, id]);

  useEffect(() => {
    getChildBpList();
  }, [getChildBpList]);

  useEffect(() => {
    dispatch(userMe(history, { customer: id }));
  }, [dispatch, history, id]);

  useEffect(() => {
    getSPData(id).then((res) => {
      if (res?.status === 200) {
        setIsAuthorized(res?.data);
      }
    });
  }, [id]);

  useEffect(() => {
    dispatch(dynamicErrorMsg(id));
  }, [dispatch, id]);

  useEffect(() => {
    // save the SP API data so that
    if (history.location.pathname?.includes('bbe_id')) {
      const spDetail = JSON.parse(localStorage.getItem('spApi'));
      const stringified =
        queryString &&
        queryString.stringify({
          ...params,
        });

      history.push({
        pathname: `${history.location.pathname.replace(
          'bbe_id',
          spDetail.customer_id,
        )}`,
        search: `${stringified}`,
      });
      const data = {
        customer: spDetail?.customer_id,
        marketplace: spDetail?.marketplace?.label,
        account_type: spDetail?.type,
        spapi_oauth_code: params.spapi_oauth_code,
        state: params.state,
        selling_partner_id: params.selling_partner_id,
        redirct_uri: history.location.pathname,
      };

      saveSPData(data).then((res) => {
        if (res?.status === 201) {
          history.push(
            `${PATH_CUSTOMER_DETAILS.replace(
              ':id',
              spDetail?.customer_id,
            )}?tab=company-details&access=${isBBEInternalUser}`,
          );
        }
      });
    }
  }, [isBBEInternalUser]);

  const redirectToSelectedTab = useCallback(() => {
    const { state } = history.location;
    const nav = state || params?.nav;
    history.push(
      `${PATH_CUSTOMER_DETAILS.replace(':id', id)}?tab=${params?.tab}${
        params?.sub ? `&sub=${params.sub}` : ''
      }${nav ? `&nav=${nav}` : ''}${
        params?.subNav ? `&subNav=${params.subNav}` : ''
      }&access=${isBBEInternalUser}`,
      nav,
    );
  }, [id, isBBEInternalUser]);

  const redirectToPerformanceTab = useCallback(
    (nav) => {
      const baseURL = `${PATH_CUSTOMER_DETAILS.replace(
        ':id',
        id,
      )}?tab=performance`;
      const { state } = history?.location;

      if (nav === DSPPERFORMANCE) {
        history.push(
          `${baseURL}&nav=${DSPPERFORMANCE}&access=${isBBEInternalUser}`,
          state || params?.nav,
        );
      } else if (nav === AMCINSIGHTS) {
        history.push(
          `${baseURL}&nav=${AMCINSIGHTS}&subNav=${
            params?.subNav || 'standard'
          }&access=${isBBEInternalUser}${
            params?.amcAudience ? '&amcAudience=lookalike' : ''
          }`,
          state || params?.nav,
        );
      } else if (nav === 'performance') {
        history.push(
          `${baseURL}${
            customerAccountType
              ? `&sub=${
                  params?.sub
                    ? params.sub
                    : customerAccountType === HYBRID ||
                      customerAccountType === SELLER
                    ? SELLERSMALL
                    : VENDORSMALL
                }`
              : ''
          }&nav=${params?.nav || SALEPERFORMANCE}&access=${isBBEInternalUser}`,
          state || params?.nav,
        );
      } else {
        redirectToSelectedTab();
      }
    },
    [customerAccountType, id, isBBEInternalUser, redirectToSelectedTab],
  );

  const redirectToCustomerView = useCallback(() => {
    const { state } = history.location;

    const tab = params?.tab;
    const showSummaryDashboard = customer?.display_summary_dashboard;
    const defaultTab =
      tab === 'undefined' || tab === null || tab === undefined
        ? showSummaryDashboard
          ? 'summary'
          : 'dashboard'
        : tab;

    if (defaultTab === 'summary' || defaultTab === 'dashboard') {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(
          ':id',
          id,
        )}?tab=${defaultTab}&access=${isBBEInternalUser}`,
        state || params?.nav,
      );
    } else {
      redirectToSelectedTab();
    }
    dispatch(setCustomerSelectedTab(defaultTab));
    setIsLoading({ loader: false, type: 'page' });
  }, [customer, dispatch, id, isBBEInternalUser]);

  useEffect(() => {
    const { state } = history?.location;

    if (invoiceTypes.includes(state)) {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(
          ':id',
          id,
        )}?tab=billing&nav=${state}&access=${isBBEInternalUser}`,
      );
    }

    if (
      inventoryTypes.includes(state) &&
      customerSelectedTab === 'inventoryManagement'
    ) {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(
          ':id',
          id,
        )}?tab=inventoryManagement&access=${isBBEInternalUser}`,
      );
    }

    if (customerSelectedTab === 'performance') {
      if (
        (state === SALEPERFORMANCE ||
          state === ADPERFORMANCE ||
          state === CREATIVEPROJECTS) &&
        customerAccountType
      ) {
        redirectToPerformanceTab('performance');
      }

      if (state === DSPPERFORMANCE) {
        redirectToPerformanceTab(DSPPERFORMANCE);
      }

      if (state === AMCINSIGHTS) {
        redirectToPerformanceTab(AMCINSIGHTS);
      }
    }
  }, [
    dispatch,
    customerSelectedTab,
    customerAccountType,
    id,
    isBBEInternalUser,
    redirectToPerformanceTab,
  ]);

  const checkUserAssignedToBP = useCallback(
    (response) => {
      const responseData = response?.length ? response : [];

      const isUserAssigned = responseData?.some(
        (member) => member?.user?.id === userId,
      );
      return isUserAssigned;
    },
    [userId],
  );

  const getCustomerMemberList = useCallback(() => {
    setIsLoading({ loader: true, type: 'page' });
    getCustomerMembers(id).then((members) => {
      if (members?.status === 200) {
        setMemberData(members?.data);
        dispatch(setCustomerMembers(members?.data));

        const isAssignedUser = checkUserAssignedToBP(members?.data);
        setIsUserAssignedToBP(isAssignedUser);
        dispatch(userMe(history, { customer: id }));
      }
      setIsLoading({ loader: false, type: 'page' });
    });
  }, [checkUserAssignedToBP, dispatch, history, id]);

  const getMarketPlace = useCallback(() => {
    let finalMarketplaces;
    const tempMarketplacesList = [];

    getMarketPlaceList(id).then((res) => {
      if (res?.status === 200) {
        finalMarketplaces =
          res && res.data.sort((value) => (value.is_primary ? -1 : 1));

        finalMarketplaces = finalMarketplaces.sort((marketplace) =>
          marketplace?.name === 'Amazon.com' && marketplace?.is_primary
            ? -1
            : marketplace?.name === 'Amazon.com'
            ? -1
            : 1,
        );

        for (const item of finalMarketplaces) {
          tempMarketplacesList.push({
            label: item?.country_currency?.country,
            value: item?.name,
          });
        }
        const tempList = [...tempMarketplacesList];
        setOriginalMarketplacesList(tempList);

        if (tempMarketplacesList?.length > 1) {
          tempMarketplacesList.unshift({
            label: 'All Marketplaces',
            value: 'all',
          });
        }
        setMarketplacesList(tempMarketplacesList);

        finalMarketplaces.unshift({
          name: 'all',
          country_currency: {
            country: 'All Marketplaces',
            currency: 'USD',
          },
        });

        setMarketplaceChoices(finalMarketplaces);
      }
    });
  }, [id]);

  if (isSaveData && !customerLoader) {
    toast.success('Changes Saved!');
    IsSaveDataClicked(false);
  }

  useEffect(() => {
    if (isCustomer) {
      redirectToCustomerView();
    }
  }, [isCustomer, redirectToCustomerView]);

  useEffect(() => {
    getMarketPlace(id);
  }, [id, getMarketPlace]);

  // PDV-7522 and PDV-7818 - bind all bps marketplace list including active & inactive
  const bindBPsAllMarketplaceList = useCallback((responseData) => {
    const tempMarketplacesList = [];
    let sortedResponseData = [];
    const allObject = {
      name: 'all',
      country_currency: {
        country: 'All Marketplaces',
        currency: 'USD',
      },
    };
    if (responseData?.length > 0) {
      sortedResponseData = responseData?.sort((value) =>
        value?.is_primary ? -1 : 1,
      );
      sortedResponseData = sortedResponseData?.sort((marketplace) =>
        marketplace?.name === 'Amazon.com' && marketplace?.is_primary
          ? -1
          : marketplace?.name === 'Amazon.com'
          ? -1
          : 1,
      );
      for (const item of sortedResponseData) {
        tempMarketplacesList.push(item);
      }
      if (tempMarketplacesList?.length > 1) {
        tempMarketplacesList.unshift(allObject);
      }
    } else {
      tempMarketplacesList.push(allObject);
    }

    return tempMarketplacesList;
  }, []);

  // PDV-7522 and PDV-7818 - get all marketplaces (recurring + dsp) data of bp
  const getBPsAllMarketplaces = useCallback(() => {
    getBPsAllMArketplaceList(id).then((response) => {
      if (response?.status === 200) {
        const formatedApidata = bindBPsAllMarketplaceList(response?.data);
        setAllMarketplacesList(formatedApidata);
      } else {
        setAllMarketplacesList([]);
      }
    });
  }, [bindBPsAllMarketplaceList, id]);

  useEffect(() => {
    getBPsAllMarketplaces();
  }, [getBPsAllMarketplaces]);

  useEffect(() => {
    dispatch(getCustomerDetails(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (!isCustomer) getCustomerMemberList();
  }, [getCustomerMemberList, isCustomer]);

  useEffect(() => {
    if (params?.tab && params?.nav === 'undefined') {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(':id', id)}?tab=${
          params.tab
        }&access=${isBBEInternalUser}`,
      );
    }
  }, [id, isBBEInternalUser]);

  const goalsAccountTypeFlagData = useCallback(() => {
    getGoalsAccountTypeFlag(id).then((response) => {
      if (response?.status === 200) {
        setGoalsAccountTypeFlag({
          seller: response?.data?.Seller,
          vendor: response?.data?.Vendor,
          hybrid: response?.data?.Hybrid,
        });
      }
    });
  }, [id]);

  useEffect(() => {
    goalsAccountTypeFlagData();
  }, [goalsAccountTypeFlagData]);

  const bindPlaybookTeamData = useCallback((response) => {
    const responseData = response?.length ? response : [];
    const tempTeamData = [
      {
        label: 'All Team Members',
        value: 'all',
      },
    ];
    if (responseData?.length) {
      for (const item of responseData) {
        tempTeamData.push({ label: item, value: item });
      }
    }
    return tempTeamData;
  }, []);

  const getPlaybookTeam = useCallback(() => {
    setPlaybookTeamsList([]);

    getPlaybookTeamData().then((response) => {
      if (response?.status === 200) {
        setPlaybookTeamsList(bindPlaybookTeamData(response?.data));
      } else {
        setPlaybookTeamsList([]);
      }
    });
  }, [bindPlaybookTeamData]);

  const bindPlaybookServiceListData = useCallback((response) => {
    const tempServicesData = [
      {
        label: 'All Services',
        value: 'all',
      },
    ];
    const serviceData = response?.length > 0 ? response : [];

    serviceData.forEach((item) => {
      tempServicesData.push({
        label: item?.name,
        value: item?.name,
        id: item?.id,
      });

      return tempServicesData;
    });
    return tempServicesData;
  }, []);

  const getPlaybookServiceList = useCallback(() => {
    setPlaybookServiceList([]);
    getPlaybookServicesListData(id).then((response) => {
      if (response?.status === 200) {
        const getData = bindPlaybookServiceListData(response?.data);
        setPlaybookServiceList(getData);
      } else {
        setPlaybookServiceList([]);
      }
    });
  }, [bindPlaybookServiceListData, id]);

  const checkOnboardingPlaybookTaskStatus = useCallback(
    (responseData) => {
      const statusData = [];
      if (responseData?.length) {
        for (const item of responseData) {
          statusData.push({ taskStatus: item?.jira_status });

          if (item?.tasks?.length) {
            for (const task of item?.tasks) {
              statusData.push({ taskStatus: task?.jira_status });

              if (task?.sub_tasks?.length) {
                for (const subTask of task?.sub_tasks) {
                  statusData.push({ taskStatus: subTask?.jira_status });
                }
              }
            }
          }
        }
      }

      const isCompleted = statusData?.every(
        (item) => item?.taskStatus === 'Done',
      );

      if (isCompleted) {
        if (playbookAccess) {
          setPlaybookTabActive('playbook');
        } else {
          setPlaybookTabActive('onboarding-gray');
        }
      } else {
        setPlaybookTabActive('onboarding');
      }
    },
    [playbookAccess],
  );

  const getOnboardingPlaybook = useCallback(() => {
    getOnboardingPlaybookData(id).then((response) => {
      if (response?.status === 200) {
        checkOnboardingPlaybookTaskStatus(response?.data);
      } else if (playbookAccess) {
        setPlaybookTabActive('playbook');
      } else {
        setPlaybookTabActive('onboarding');
      }
    });
  }, [checkOnboardingPlaybookTaskStatus, id, playbookAccess]);

  useEffect(() => {
    if (onboardingPlaybookAccess) {
      getOnboardingPlaybook();
    }
  }, [getOnboardingPlaybook, onboardingPlaybookAccess]);

  useEffect(() => {
    getPlaybookTeam();
  }, [getPlaybookTeam]);

  useEffect(() => {
    if (playbookAccess) {
      getPlaybookServiceList();
    }
  }, [getPlaybookServiceList, playbookAccess]);

  // use effect for show playbook tab on LHS
  useEffect(() => {
    // Playbook tab is visible for only those company which is currently "ACTIVE"
    if (
      isCustomerActive &&
      (playbookAccess || onboardingPlaybookAccess)
      //  || isCustomer
    ) {
      setShowPlaybook(true);
    } else {
      setShowPlaybook(false);
      if (params?.tab === 'playbook') {
        redirectToPerformanceTab('performance');
      }
    }

    /* ***** When user click on "REFRESH" button inside playbook tab then history.location will be saved and page 
    will be reloaded. Once page is reloaded, then user will land in the selected playbook tab ***** */
    const { state } = history?.location;
    const { search } = history?.location;

    const isPlaybookTab =
      state?.includes('main-playbook') || search?.includes('nav=playbook');
    const isOnboardingTab =
      state?.includes('onboarding') || search?.includes('nav=onboarding');

    if (isPlaybookTab && playbookAccess) {
      setPlaybookTabActive('playbook');
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(
          ':id',
          id,
        )}?tab=playbook&nav=playbook&access=${isBBEInternalUser}`,
      );
    } else if (isOnboardingTab && onboardingPlaybookAccess) {
      setPlaybookTabActive('onboarding');
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(
          ':id',
          id,
        )}?tab=playbook&nav=onboarding&access=${isBBEInternalUser}`,
      );
    } else if (isCustomer) {
      redirectToCustomerView();
    } else {
      redirectToSelectedTab('2222222222');
    }
  }, [
    playbookAccess,
    onboardingPlaybookAccess,
    customerAccountType,
    isCustomer,
    isCustomerActive,
    redirectToPerformanceTab,
    id,
    isBBEInternalUser,
    redirectToCustomerView,
    redirectToSelectedTab,
  ]);

  if (customer && (customer.status !== null || customer.status !== 'pending')) {
    if (showPlaybook) {
      viewOptions = sideBarMenu.forPlaybookView;
    } else {
      viewOptions = sideBarMenu.defaultView;
    }

    if (userInfo?.role?.includes('Customer')) {
      if (showPlaybook && customer?.account_type !== PARENT) {
        viewOptions = sideBarMenu.customerDefaultViewWithPlaybook;
      } else {
        viewOptions = sideBarMenu.customerDefaultView;
      }
    }
  }

  // handle side panel toggle events
  const handleSidePanelToggleEvents = () => {
    const transitionDuration = '200';
    // this should be equal to OR less than transition duration used for CustomerTabCollapse in CustomerDetailStyle.js such as ---> transition: width 300ms ease-in-out;
    if (sidePanelToggle?.isToggle) {
      setSidePanelToggle({ isToggle: false, width: '75px' });
    } else {
      setSidePanelToggle({ isToggle: true, width: '300px' });
    }
    // show and hide the tab label and dropdown icon
    $('.tab-label').toggle(transitionDuration);
    $('.collapse-icon').toggle();
  };

  const displayTeamMemberSection = () => {
    return (
      <div id="sticky-row">
        <div className="row">
          <div className="col-12 col-md-5">
            <div
              role="presentation"
              onClick={() => dispatch(setCustomerSelectedTab(''))}
            >
              {!isCustomer ? (
                <Link
                  to={{
                    pathname: PATH_CUSTOMER_LIST,
                  }}
                >
                  <div className="back-btn-link ">
                    <img className="left-arrow" src={LeftArrowIcon} alt="" />
                    Back to all partners
                  </div>
                </Link>
              ) : (
                ''
              )}
            </div>

            <div
              className={`brand-name black-heading-title ${
                isCustomer ? '' : 'my-2'
              } ${onScrollPage ? 'show' : 'hide'}`}
            >
              {customer?.company_name}
            </div>
          </div>
          <div className="col-12 col-md-7">
            <ShowTeamMembers
              role={userInfo && userInfo.role}
              setShowMemberList={setShowMemberList}
              memberData={memberData}
              setShowNotesModal={setShowNotesModal}
              isChildBPList={childBPList && childBPList.length}
            />
          </div>
        </div>
      </div>
    );
  };

  // display sidepanel collapse icon
  const displaySidePanelCollapseIcon = () => {
    return (
      <div
        role="presentation"
        className={`cursor hamburger-menu ${
          sidePanelToggle?.isToggle ? 'menu-active' : ''
        }`}
        onClick={() => {
          handleSidePanelToggleEvents();
        }}
      >
        {/* Empty div for hamburger-menu */}
        <div />
        <div />
        <div />
      </div>
    );
  };

  const displayCustomerTabCollapse = () => {
    return (
      <CustomerTabList
        style={{
          maxWidth: sidePanelToggle?.isToggle ? '270px' : '45px',
        }}
      >
        {displaySidePanelCollapseIcon()}
        <CustomerTabDetails
          role={userInfo && userInfo.role}
          customer={customer}
          showPlaybook={showPlaybook}
          viewComponent={params.tab}
          subViewComponent={params.sub}
          playbookTabActive={playbookTabActive}
          isBBEInternalUser={isBBEInternalUser}
          sidePanelToggle={sidePanelToggle}
          setShowCreativeUploadScreen={setShowCreativeUploadScreen}
        />
      </CustomerTabList>
    );
  };

  const displayTabDropdown = () => {
    return (
      <Select
        options={viewOptions}
        className="customer-dropdown-select d-lg-none d-block mb-3 "
        onChange={(event) => {
          history.push(
            `${PATH_CUSTOMER_DETAILS.replace(':id', id)}?tab=${event.value}${
              event.value === 'performance' && customerAccountType
                ? `&sub=${
                    params?.sub
                      ? params.sub
                      : customerAccountType === HYBRID ||
                        customerAccountType === SELLER
                      ? SELLERSMALL
                      : VENDORSMALL
                  }`
                : ''
            }${event.nav ? `&nav=${event.nav}` : ''}`,
            params.nav,
          );

          dispatch(setCustomerSelectedTab(event.value));
        }}
        value={
          params?.tab === 'playbook'
            ? viewOptions[3]
            : viewOptions?.find((item) => item.value === params?.tab)
        }
      />
    );
  };

  const displayCustomerHeaderAndTabs = () => {
    return (
      <div className="col-12">
        <WhiteCard className="mb-4">
          <AccountDetails
            id={id}
            setShowModal={setShowModal}
            role={userInfo && userInfo.role}
            setStatusModal={setStatusModal}
            customerData={customer}
            showModal={showModal}
            IsSaveDataClicked={IsSaveDataClicked}
            customStyles={customStyles}
            isAuthorized={isAuthorized}
            childBPList={childBPAllList}
            history={history}
            getChildBpList={getChildBpList}
            customerType={customer && customer.account_type}
          />
        </WhiteCard>
        {displayTabDropdown()}
      </div>
    );
  };

  const displayPerformanceTabContainer = () => {
    return (
      <CompanyPerformance
        id={id}
        customer={customer}
        memberData={memberData}
        customerName={customerName}
        subViewComponent={params?.sub}
        childBPList={childBPList}
        isBBEInternalUser={isBBEInternalUser}
        loggedInMail={userInfo?.email}
        marketplaceChoices={allMarketplacesList}
        showAdperformanceSpecificBP={customer?.display_ad_performance}
        showSalesReportSpecificBP={
          customer?.display_sales_performance || customer?.display_seller_health
        }
        setShowCreativeUploadScreen={setShowCreativeUploadScreen}
        showCreativeUploadScreen={showCreativeUploadScreen}
      />
    );
  };
  const displayInventoryTabContainer = () => {
    return (
      <InventoryManagementContainer
        marketplaceChoices={marketplaceChoices}
        id={id}
        subViewComponent={params?.sub}
        memberData={memberData}
        childBPList={childBPList}
      />
    );
  };

  const displayAgreementTabContainer = () => {
    return (
      <AgreementDetailContainer
        id={id}
        setShowMemberList={setShowMemberList}
        showModal={agreementDetailModal}
        setShowModal={setAgreementDetailModal}
        userRole={userInfo?.role}
        customerStatus={customer && customer.status && customer.status.value}
        memberData={memberData}
        params={params}
        isBBEInternalUser={isBBEInternalUser}
        childBPList={childBPList}
      />
    );
  };

  const displayCompanyDetailTabContainer = () => {
    return (
      <CompanyDetail
        id={id}
        customer={customer}
        seller={
          agreement && agreement.seller_type && agreement.seller_type.value
        }
        isCustomer={isCustomer}
        userInfo={userInfo}
        isAuthorized={isAuthorized}
        childBPList={childBPList}
        setIsAuthorized={setIsAuthorized}
      />
    );
  };

  const displayBillingTabContainer = () => {
    return (
      <BillingContainer
        redirectType={
          billingNavigationOptions.includes(history.location.state)
            ? history.location.state
            : params.nav
        }
        id={id}
        userInfo={userInfo}
        customerStatus={customer?.status}
        onBoardingId={customer?.customer_onboarding_id}
        memberData={memberData}
        bpName={customer?.company_name}
        childBPList={childBPList}
      />
    );
  };

  const displayPlaybookTabContainer = () => {
    return (
      <PlaybookContainer
        customerId={id}
        isCustomer={isCustomer}
        currentYear={currentYear}
        playbookEpicId={playbookEpicId}
        marketplacesList={marketplacesList}
        playbookTeamsList={playbookTeamsList}
        playbookTabActive={playbookTabActive}
        isBBEInternalUser={isBBEInternalUser}
        isUserAssignedToBP={isUserAssignedToBP}
        customerAccountType={customerAccountType}
        playbookServiceList={playbookServiceList}
        originalMarketplaceList={originalMarketplaceList}
        playbookAccess={playbookAccess}
        onboardingPlaybookAccess={onboardingPlaybookAccess}
      />
    );
  };

  const displayAcivityTabContainer = () => {
    return (
      <Activity
        id={id}
        viewComponent={params.tab}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        childBPList={childBPList}
        customerName={customer.company_name}
        customerType={customer && customer.account_type}
      />
    );
  };

  const displayFeedbackTabContainer = () => {
    return (
      <CustomerFeedbackList
        id={id}
        childBPList={childBPList}
        customerType={customer && customer.account_type}
        memberData={memberData}
      />
    );
  };

  const displayForecastTabContainer = () => {
    return (
      <GoalsAndCosContainer
        isBBEInternalUser={isBBEInternalUser}
        goalsAccountTypeFlag={goalsAccountTypeFlag}
      />
    );
  };

  const displayDashboardTabContainer = () => {
    return (
      <SetupCheckList
        id={id}
        brandId={customer?.brand_assets?.id}
        productAssetsId={customer?.product_assets?.id}
      />
    );
  };

  const displaySummaryTabContainer = () => {
    return (
      <SummaryViewContainer marketplaceChoices={marketplaceChoices} id={id} />
    );
  };

  const displayCustomerTabContent = () => {
    switch (params?.tab) {
      case 'agreement':
        return displayAgreementTabContainer();
      case 'product catalog':
        return <ProductCatalog id={id} />;
      case 'company-details':
        return displayCompanyDetailTabContainer();
      case 'dashboard':
        return displayDashboardTabContainer();
      case 'summary':
        return displaySummaryTabContainer();
      case 'performance':
        return displayPerformanceTabContainer();
      case 'inventoryManagement':
        return displayInventoryTabContainer();
      case 'billing':
        return displayBillingTabContainer();
      case 'playbook':
        return displayPlaybookTabContainer();
      case 'activity':
        return displayAcivityTabContainer();
      case 'reports':
        return <ReportsSectionContainer />;
      case 'feedback':
        return displayFeedbackTabContainer();
      case 'goals':
        return displayForecastTabContainer();
      default:
        return null;
    }
  };

  const displayCustomerModals = () => {
    return (
      <>
        <TeamMemberModal
          id={id}
          currentMembers={memberData}
          showMemberList={showMemberList}
          setShowMemberList={setShowMemberList}
          setAgreementDetailModal={setAgreementDetailModal}
          customStyles={customStyles}
          getCustomerMemberList={getCustomerMemberList}
        />
        <CustomerStatusModal
          statusModal={statusModal}
          customStyles={customStyles}
          setStatusModal={setStatusModal}
          customer={customer}
        />
        <NotesModal
          id={id}
          showNotesModal={showNotesModal}
          setShowNotesModal={setShowNotesModal}
          setNewNoteEditor={setNewNoteEditor}
          showNewNoteEditor={showNewNoteEditor}
          customerName={
            childBPList && childBPList.length
              ? `${customer.company_name} Notes`
              : 'Notes'
          }
        />
        <OtherModals id={id} />
      </>
    );
  };

  return (
    <>
      {!params?.selling_partner_id &&
      !history.location.pathname.includes('bbe_id') &&
      (customerError?.status === 404 || customerError?.status === 403) ? (
        <PageNotFound />
      ) : (
        <>
          {customerLoader ||
          (isLoading?.loader && isLoading?.type === 'page') ? (
            <PageLoader color={Theme.orange} type="page" width={20} />
          ) : (
            <>
              <div className="d-flex">
                <CustomerTabCollapse
                  id="customerTabCollapse"
                  style={{
                    width: isDesktop ? sidePanelToggle?.width : '0%',
                    display: isDesktop ? 'block' : 'none',
                  }}
                >
                  {displayCustomerTabCollapse()}
                </CustomerTabCollapse>
                <div
                  style={{
                    width: isDesktop
                      ? `calc(100% - ${sidePanelToggle?.width})`
                      : '100%',
                  }}
                >
                  <CustomerDetailsBody role={userInfo && userInfo.role}>
                    {displayTeamMemberSection()}
                    <div className="row mt-3 pt-2">
                      {displayCustomerHeaderAndTabs()}
                      {displayCustomerTabContent()}
                    </div>
                  </CustomerDetailsBody>
                  <div className="col-12 mt-5">
                    <BackToTop />
                  </div>
                  {displayCustomerModals()}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
