/* eslint-disable camelcase */
import React, { Fragment, useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import Theme from '../../../../theme/Theme';
import SummaryPerformanceGraph from './SummaryPerformanceGraph';
import useFormatNumber from '../../../../hooks/useFormatNumber';
import { ArrowUpIcon, ArrowDownIcon } from '../../../../theme/images';
import { setCustomerSelectedTab } from '../../../../store/actions/customerState';
import {
  dateOptionsWithYear,
  noGraphDataMessage,
  metricsClasses,
  revenuMetricsSubTypes,
  PATH_CUSTOMER_DETAILS,
  metricsResponseKey,
  adSpendMetricsSubTypes,
  showMetricsTooltipForSales,
  tooltipNames,
  revenuSubTypeKeys,
  metricsTootlNames,
} from '../../../../constants';
import {
  WhiteCard,
  PageLoader,
  CustomDateModal,
  NoData,
  DropdownIndicator,
  ToggleButton,
  CardMetrics,
  CheckBox,
} from '../../../../common';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import BreakDown from './SummaryPerformanceStyle';

const _ = require('lodash');

am4core.useTheme(am4themes_dataviz);
export default function SummaryPerformanceView({
  renderCustomDateSubLabel,
  selectedPeformanceMetrics,
  currencySymbol,
  setSelectedPeformanceMetrics,
  handleTimeFrameFilter,
  selectedTimeFrame,
  showCustomDateModal,
  customDateState,
  setShowCustomDateModal,
  setCustomDateState,
  currentDate,
  applyCustomDate,
  handleGroupBy,
  groupBy,
  performanceCurrentTotal,
  performanceDifference,
  performancePreviousTotal,
  performanceGraphLoader,
  summaryPerformanceGraphData,
  isMetricsDisabled,
  isDisabledTimeFrame,
  setIsDisabledTimeFrame,
  daysDiff,
  generateSingleSelectDropdownHTML,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { Option, SingleValue } = components;
  const customerState = useSelector((state) => state.customerState.data);
  const [isRevenueDropDownOpen, setIsRevenueDropDownOpen] = useState(false);
  const [selectedRevenueSubType, setSelectedRevenueSubType] = useState({
    revenueTotal: true,
    revenuePpc: false,
    revenueDsp: false,
  });

  const [isAdSpendDropDownOpen, setIsAdSpendDropDownOpen] = useState(false);
  const [selectedAdSpendSubType, setSelectedAdSpendSubType] = useState({
    adSpendTotal: true,
    sponsoredAdSpend: false,
    dspSpend: false,
  });

  function useOutsideAlerter(ref, type) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (type === 'adSpend') setIsAdSpendDropDownOpen(false);
          else setIsRevenueDropDownOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, type]);
  }

  const revenueRef = useRef(null);
  useOutsideAlerter(revenueRef, 'revenue');
  const advertisingRef = useRef(null);
  useOutsideAlerter(advertisingRef, 'adSpend');

  const filterOption = (props) => (
    <Option {...props}>
      <div className="pb-2">
        <span style={{ fontSize: '15px', color: '#000000' }}>
          {props.data.label}
          {props?.isDisabled === true ? (
            <>
              <FontAwesomeIcon
                icon=" fal fa-info-circle"
                color={Theme.orange}
                fontSize="13px"
                style={{ marginLeft: '20px' }}
                data-tip="Selected metrics has no data for this period."
                data-for="adSalesTotalInfo"
              />
              <ReactTooltip
                class="initialTextTransform"
                id="adSalesTotalInfo"
                aria-haspopup="true"
                place="right"
                effect="solid"
              />
            </>
          ) : null}
        </span>

        <div style={{ fontSize: '12px', color: '#556178' }}>
          {props.data.sub}
        </div>
      </div>
    </Option>
  );

  const singleFilterOption = (props) => (
    <SingleValue {...props}>
      <span style={{ fontSize: '15px', color: '#000000' }}>
        {props.data.label}
      </span>

      <div style={{ fontSize: '12px', color: '#556178' }}>
        {renderCustomDateSubLabel(props, 'sp')}
      </div>
    </SingleValue>
  );

  const getSelectComponents = () => {
    return {
      Option: filterOption,
      SingleValue: singleFilterOption,
      DropdownIndicator,
    };
  };

  const bindValues = (value, isShowCurrencySymbol) => {
    const decimal = _.split(value, '.', 2);
    if (decimal[1] !== undefined) {
      return (
        <span style={{ fontSize: '26px' }}>
          {isShowCurrencySymbol ? currencySymbol : null}
          {decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          <span style={{ fontSize: '16px' }}>.{decimal[1].slice(0, 2)}</span>
        </span>
      );
    }
    return (
      <span style={{ fontSize: '26px' }}>
        {isShowCurrencySymbol ? currencySymbol : null}
        {decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    );
  };

  const setPerformanceMetricsClass = (name, classValue) => {
    let selectedClass = '';
    if (
      !performanceCurrentTotal?.revenue &&
      !performancePreviousTotal?.revenue &&
      Object.prototype.hasOwnProperty.call(selectedPeformanceMetrics, name)
    ) {
      selectedClass = `order-chart-box ${classValue} fix-height disabled`;
    } else if (
      !performanceCurrentTotal?.ad_spend_total &&
      !performancePreviousTotal?.ad_spend_total &&
      name === 'adSpendTotal'
    ) {
      selectedClass = `order-chart-box fix-height disabled`;
    } else if (
      Object.prototype.hasOwnProperty.call(selectedPeformanceMetrics, name)
    ) {
      selectedClass = `order-chart-box ${classValue} fix-height`;
    } else if (
      _.size(selectedPeformanceMetrics) === 4 ||
      (isMetricsDisabled[name] &&
        ![
          'revenueTotal',
          'revenueOrganic',
          'revenuePpc',
          'revenueDsp',
        ].includes(name)) ||
      (['revenueTotal', 'revenueOrganic', 'revenuePpc', 'revenueDsp'].includes(
        name,
      ) &&
        isMetricsDisabled.revenueTotal &&
        isMetricsDisabled.revenueOrganic &&
        isMetricsDisabled.revenuePpc &&
        isMetricsDisabled.revenueDsp)
    ) {
      selectedClass = 'order-chart-box fix-height disabled';
    } else {
      selectedClass = 'order-chart-box fix-height';
    }
    return selectedClass;
  };

  const setBoxToggle = (name) => {
    // Below Commented this code beacuase PDV-6723
    // const localMetrics = selectedPeformanceMetrics;
    if (
      Object.prototype.hasOwnProperty.call(selectedPeformanceMetrics, name) &&
      _.size(selectedPeformanceMetrics) > 1
    ) {
      setSelectedPeformanceMetrics(_.omit(selectedPeformanceMetrics, [name]));

      // enable the time frame filter when we don't have a disabled selected metrics.
      // const commanElement = _.intersectionWith(
      //   _.keys(localMetrics),
      //   _.keys(_.pickBy(isMetricsDisabled)),
      //   _.isEqual,
      // );
      // if (commanElement.length === 1 && name === commanElement[0]) {
      //   setIsDisabledTimeFrame(false);
      // }
    } else if (_.size(selectedPeformanceMetrics) < 4) {
      setSelectedPeformanceMetrics(
        _.omit(_.assign(selectedPeformanceMetrics, { [name]: true })),
      );
    }
  };

  const setRevenueMetricsToggle = (name) => {
    const localMetrics = { ...selectedPeformanceMetrics };
    const otherMetrics = [
      'sponsoredAdSpend',
      'dspSpend',
      'adSpendTotal',
      'unitsSold',
      'traffic',
      'conversion',
    ];
    // remove the metrics
    if (
      Object.prototype.hasOwnProperty.call(selectedPeformanceMetrics, name) &&
      _.keys(selectedPeformanceMetrics).some((item) =>
        otherMetrics.includes(item),
      )
    ) {
      // enable the time frame filter when we don't have a disabled selected metrics.
      const commanElement = _.intersectionWith(
        _.keys(localMetrics),
        _.keys(_.pickBy(isMetricsDisabled)),
        _.isEqual,
      );

      if (commanElement.length === 1) {
        setIsDisabledTimeFrame(false);
      }

      // set the default drop-down value as- revenueTotal
      delete localMetrics.revenueTotal;
      delete localMetrics.revenueDsp;
      delete localMetrics.revenuePpc;

      setSelectedPeformanceMetrics(localMetrics);
      setSelectedRevenueSubType({
        revenueTotal: true,
        revenuePpc: false,
        revenueDsp: false,
      });
    } else if (_.size(selectedPeformanceMetrics) < 4) {
      // select the metrics
      setSelectedPeformanceMetrics(
        _.omit(_.assign(selectedPeformanceMetrics, { [name]: true })),
      );
    }
  };

  const setAdSpendMetricsToggle = (name) => {
    const localMetrics = { ...selectedPeformanceMetrics };
    const otherMetrics = [
      'revenueTotal',
      'revenuePpc',
      'revenueOrganic',
      'revenueDsp',
      'unitsSold',
      'traffic',
      'conversion',
    ];
    // remove the metrics
    if (
      Object.prototype.hasOwnProperty.call(selectedPeformanceMetrics, name) &&
      _.keys(selectedPeformanceMetrics).some((item) =>
        otherMetrics.includes(item),
      )
    ) {
      // enable the time frame filter when we don't have a disabled selected metrics.
      const commanElement = _.intersectionWith(
        _.keys(localMetrics),
        _.keys(_.pickBy(isMetricsDisabled)),
        _.isEqual,
      );
      if (commanElement.length === 1) {
        setIsDisabledTimeFrame(false);
      }

      // set the default drop-down value as- adSpendTotal
      delete localMetrics.sponsoredAdSpend;
      delete localMetrics.dspSpend;
      delete localMetrics.adSpendTotal;

      setSelectedPeformanceMetrics(localMetrics);
      setSelectedAdSpendSubType({
        adSpendTotal: true,
        sponsoredAdSpend: false,
        dspSpend: false,
      });
    } else if (_.size(selectedPeformanceMetrics) < 4) {
      // select the metrics
      setSelectedPeformanceMetrics(
        _.omit(_.assign(selectedPeformanceMetrics, { [name]: true })),
      );
    }
  };

  const onHandleRevenueSubType = (event) => {
    // get selected dropdown keys
    let localSelectedSubype = _.keys(
      _.pickBy(selectedRevenueSubType, _.identity),
    );

    // at least one option is selected
    if (
      localSelectedSubype.includes(event.target.id) &&
      _.size(localSelectedSubype) === 1
    ) {
      return;
    }

    if (
      _.size(selectedPeformanceMetrics) === 3 &&
      !_.keys(selectedPeformanceMetrics).some((item) =>
        localSelectedSubype.includes(item),
      )
    ) {
      setSelectedRevenueSubType({
        ...selectedRevenueSubType,
        revenueTotal: false,
        [event.target.id]: !selectedRevenueSubType[event.target.id],
      });
      localSelectedSubype = _.drop(localSelectedSubype);
    } else {
      setSelectedRevenueSubType({
        ...selectedRevenueSubType,
        [event.target.id]: !selectedRevenueSubType[event.target.id],
      });
    }

    // if metrics is not selected and we directly clicked on dropdown option then we we need to set the others option too
    if (!_.keys(selectedPeformanceMetrics).includes(event.target.id)) {
      for (const option of localSelectedSubype) {
        if (!_.keys(selectedPeformanceMetrics).includes(option)) {
          setBoxToggle(option);
        }
      }
    }

    setBoxToggle(event.target.id);
  };

  const onHandleAdSpendeSubType = (event) => {
    // get selected dropdown keys
    let localSelectedSubype = _.keys(
      _.pickBy(selectedAdSpendSubType, _.identity),
    );

    // at least one option is selected
    if (
      localSelectedSubype.includes(event.target.id) &&
      _.size(localSelectedSubype) === 1
    ) {
      return;
    }

    if (
      _.size(selectedPeformanceMetrics) === 3 &&
      !_.keys(selectedPeformanceMetrics).some((item) =>
        localSelectedSubype.includes(item),
      )
    ) {
      setSelectedAdSpendSubType({
        ...selectedAdSpendSubType,
        adSpendTotal: false,
        [event.target.id]: !selectedAdSpendSubType[event.target.id],
      });
      localSelectedSubype = _.drop(localSelectedSubype);
    } else {
      setSelectedAdSpendSubType({
        ...selectedAdSpendSubType,
        [event.target.id]: !selectedAdSpendSubType[event.target.id],
      });
    }
    if (!_.keys(selectedPeformanceMetrics).includes(event.target.id)) {
      // if metrics is not selected and we directly clicked on dropdown option then we we need to set the others option too
      for (const option of localSelectedSubype) {
        if (!_.keys(selectedPeformanceMetrics).includes(option)) {
          setBoxToggle(option);
        }
      }
    }

    setBoxToggle(event.target.id);
  };

  const renderFilterDropDown = () => {
    const localDateOptionsWithYear = dateOptionsWithYear;

    // PDV-6572 If PPC or DSP selected then disabled the year_to_date option
    localDateOptionsWithYear[3].isDisabled = _.keys(
      selectedPeformanceMetrics,
    ).some((item) => ['revenuePpc', 'revenueDsp'].includes(item));

    let options = [...localDateOptionsWithYear];
    if (daysDiff && options) {
      const updatedOption = options.map((obj) => {
        if (obj.value === 'week' && daysDiff < 7) {
          obj.isDisabled = true;
        }
        if (obj.value === 'month' && daysDiff < currentDate?.getDate()) {
          obj.isDisabled = true;
        }
        if (obj.value === '30days' && daysDiff < 30) {
          obj.isDisabled = true;
        }

        if (
          obj.value === 'year' &&
          daysDiff <
            dayjs(currentDate).diff(
              dayjs(`${currentDate?.getFullYear()}-01-01`),
              'days',
            )
        ) {
          obj.isDisabled = true;
        }

        return obj;
      });
      options = [...updatedOption];
    }

    return (
      <div className="col-xl-3 col-md-6 col-sm1-12 mb-3">
        {generateSingleSelectDropdownHTML(
          'BT-SummaryPerformanceTimeFrame',
          '',
          options,
          selectedTimeFrame,
          handleTimeFrameFilter,
          getSelectComponents,
          performanceGraphLoader || isDisabledTimeFrame,
          false,
        )}
      </div>
    );
  };

  const rednerPeformanceGroupByFilters = () => {
    return (
      <div className="row mt-4 mb-3">
        {_.size(selectedPeformanceMetrics) <= 2 ? (
          <div className="col-md-5 col-sm-12 order-md-1 order-2 mt-2 pr-0">
            <ul className="rechart-item">
              <li>
                <div className="weeks">
                  <span
                    className={
                      _.size(selectedPeformanceMetrics) === 1
                        ? `${
                            _.keys(selectedPeformanceMetrics)[0]
                          } adSales circle`
                        : 'darkGray circle'
                    }
                  />
                  <span>Recent</span>
                </div>
              </li>
              {selectedTimeFrame.value !== 'custom' ? (
                <li>
                  <div className="weeks">
                    <ul className="dashed-line">
                      <li
                        className={
                          _.size(selectedPeformanceMetrics) === 1
                            ? `${_.keys(selectedPeformanceMetrics)[0]} block `
                            : 'darkGray block '
                        }
                      />
                      <li
                        className={
                          _.size(selectedPeformanceMetrics) === 1
                            ? `${_.keys(selectedPeformanceMetrics)[0]} block  `
                            : 'darkGray block '
                        }
                      />
                    </ul>

                    <span>Previous</span>
                  </div>
                </li>
              ) : null}
            </ul>
          </div>
        ) : (
          <div className="col-md-5 col-sm-12 order-md-1 order-2 mt-2 " />
        )}
        <div className="col-md-7 col-sm-12 order-md-2 order-1 pl-0">
          <ToggleButton>
            <div className="days-container ">
              <ul
                className={
                  performanceGraphLoader ? 'disabled-tab days-tab' : 'days-tab'
                }
              >
                <li>
                  <input
                    className="d-none"
                    type="radio"
                    id="daysCheck"
                    name="flexRadioDefault"
                    value={groupBy}
                    checked={groupBy === 'daily'}
                    onClick={() => handleGroupBy('daily')}
                    onChange={() => {}}
                  />
                  <label htmlFor="daysCheck">Daily</label>
                </li>

                <li>
                  <input
                    className="d-none"
                    type="radio"
                    value={groupBy}
                    checked={groupBy === 'weekly'}
                    id="weeklyCheck"
                    name="flexRadioDefault"
                    onChange={() => handleGroupBy('weekly')}
                  />
                  <label htmlFor="weeklyCheck">Weekly</label>
                </li>

                <li>
                  <input
                    className=" d-none"
                    type="radio"
                    value={groupBy}
                    checked={groupBy === 'monthly'}
                    id="monthlyCheck"
                    name="flexRadioDefault"
                    onChange={() => handleGroupBy('monthly')}
                  />
                  <label htmlFor="monthlyCheck">Monthly</label>
                </li>
              </ul>
            </div>
          </ToggleButton>
        </div>
      </div>
    );
  };

  const returnTooltipMessge = (key = '') => {
    return `No ${key} available for this period.`;
  };

  const renderRevenueMetrics = (metricsName, formatNumber) => {
    let currentTotal = 0;
    let previousTotal = 0;
    let difference = 0;
    let boxActiveClass = metricsClasses[metricsName];
    let name = metricsName;
    let dropdownName = '';

    let responseKey = metricsResponseKey[name];

    // find out keys which is having true value OR seperate out selected option.
    const localSelectedSubType = _.keys(
      _.pickBy(selectedRevenueSubType, _.identity),
    );
    [name] = localSelectedSubType;
    boxActiveClass = metricsClasses[localSelectedSubType[0]];

    // If the user selects multiple metrics with “Total” being one of them then display the total value
    if (selectedRevenueSubType.revenueTotal) {
      dropdownName = 'Total Sales';
      responseKey = metricsResponseKey.revenueTotal;
      currentTotal =
        performanceCurrentTotal && performanceCurrentTotal[responseKey]
          ? performanceCurrentTotal[responseKey]
          : 0;
      previousTotal =
        performancePreviousTotal && performancePreviousTotal[responseKey]
          ? performancePreviousTotal[responseKey]
          : 0;
      difference =
        performanceDifference && performanceDifference[responseKey]
          ? performanceDifference[responseKey]
          : 'N/A';
    } // If the user selects both PPC and DSP then sum up both values
    else if (
      !selectedRevenueSubType.revenueTotal &&
      localSelectedSubType.length > 1
    ) {
      dropdownName = 'PPC & DSP Sales';
      const dspCurrentTotal = performanceCurrentTotal?.revenue_dsp
        ? performanceCurrentTotal.revenue_dsp
        : 0;

      const dspPreviousTotal = performancePreviousTotal?.revenue_dsp
        ? performancePreviousTotal.revenue_dsp
        : 0;

      const ppcCurrentTotal = performanceCurrentTotal?.revenue_ppc
        ? performanceCurrentTotal.revenue_ppc
        : 0;

      const ppcPreviousTotal = performancePreviousTotal?.revenue_ppc
        ? performancePreviousTotal.revenue_ppc
        : 0;

      currentTotal = dspCurrentTotal + ppcCurrentTotal;
      previousTotal = dspPreviousTotal + ppcPreviousTotal;
      difference = ((currentTotal - previousTotal) * 100) / previousTotal;
    } // If the user selects a single metric just display the value for this metric
    else {
      if (localSelectedSubType[0] === 'revenuePpc') {
        dropdownName = 'PPC Sales';
      } else if (localSelectedSubType[0] === 'revenueDsp') {
        dropdownName = 'DSP Sales';
      }

      responseKey = metricsResponseKey[localSelectedSubType[0]];
      currentTotal = performanceCurrentTotal?.[responseKey]
        ? performanceCurrentTotal[responseKey]
        : 0;
      previousTotal = performancePreviousTotal?.[responseKey]
        ? performancePreviousTotal[responseKey]
        : 0;
      difference = performanceDifference?.[responseKey]
        ? performanceDifference[responseKey]
        : 'N/A';
    }

    const firstSelectedMetrics = Object.keys(selectedRevenueSubType).find(
      (i) => selectedRevenueSubType[i] === true,
    );

    return (
      <div className="col-lg-4 col-md-6 col-12 pr-1 pl-0 mb-3">
        <CardMetrics
          className="fix-height"
          data-tip={returnTooltipMessge(
            metricsTootlNames[firstSelectedMetrics],
          )}
          data-for={firstSelectedMetrics}
        >
          <div
            className={setPerformanceMetricsClass(name, boxActiveClass)}
            onClick={() => {
              setRevenueMetricsToggle(name);
            }}
            role="presentation"
          >
            {' '}
            <>
              <div className="row ">
                <div className="chart-name col-6 pr-0">
                  REVENUE
                  {customerState?.display_sales_performance ? (
                    <FontAwesomeIcon
                      icon="fa-light fa-square-arrow-up-right"
                      color={Theme.orange}
                      fontSize="13px"
                      style={{ marginLeft: '4px' }}
                      onClick={() => {
                        dispatch(setCustomerSelectedTab('performance'));
                        history.push(
                          `${PATH_CUSTOMER_DETAILS.replace(
                            ':id',
                            customerState?.id,
                          )}?tab=performance${
                            customerState?.customer_account_type
                              ? `&sub=${
                                  customerState?.customer_account_type ===
                                    'Hybrid' ||
                                  customerState?.customer_account_type ===
                                    'Seller'
                                    ? 'seller'
                                    : 'vendor'
                                }`
                              : ''
                          }&nav=salePerformance&access=external`,
                          'salePerformance',
                        );
                      }}
                    />
                  ) : null}
                </div>

                <div className="col-6 mt-n2 pl-1 text-right">
                  <BreakDown
                    id="BT-RevenuBreakDownBP"
                    ref={revenueRef}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div
                      className={`dropdown-revenue-input ${
                        performanceGraphLoader ? `disabled` : ''
                      }`}
                      role="presentation"
                      onClick={() => {
                        setIsRevenueDropDownOpen(!isRevenueDropDownOpen);
                      }}
                      id="clickbox"
                    >
                      <span className="selected-list"> {dropdownName}</span>
                      <FontAwesomeIcon
                        icon="fa-regular fa-angle-down"
                        className="dropdown-arrow-icon"
                        style={{
                          transform: isRevenueDropDownOpen
                            ? 'rotate(180deg)'
                            : '',
                        }}
                      />
                    </div>

                    {isRevenueDropDownOpen ? (
                      <div
                        className="dropdown-revenue-filter "
                        style={{ cursor: 'initial' }}
                      >
                        <ul className="options-list">
                          {revenuMetricsSubTypes.map((item) => {
                            return (
                              <li
                                key={item.id}
                                className={
                                  isMetricsDisabled[item.value] ||
                                  (_.size(selectedPeformanceMetrics) === 4 &&
                                    !selectedRevenueSubType[item.value])
                                    ? 'disabled'
                                    : ''
                                }
                              >
                                {' '}
                                <CheckBox className="mt-3 mb-3">
                                  <label
                                    className="check-container customer-pannel "
                                    id={item.label}
                                    htmlFor={item.value}
                                  >
                                    <input
                                      type="checkbox"
                                      id={item.value}
                                      checked={
                                        selectedRevenueSubType[item.value]
                                      }
                                      onChange={(event) => {
                                        onHandleRevenueSubType(event);
                                      }}
                                      disabled={
                                        isMetricsDisabled[item.value] ||
                                        (_.size(selectedPeformanceMetrics) ===
                                          4 &&
                                          !selectedRevenueSubType[item.value])
                                      }
                                    />
                                    <span className="checkmark" />
                                    {item.label}
                                  </label>
                                </CheckBox>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </BreakDown>
                </div>
              </div>
            </>
            <div className="number-rate">{bindValues(currentTotal, true)}</div>
            <div className="justify-space-between ">
              <div className="vs">
                {' '}
                vs {formatNumber(previousTotal, currencySymbol, '')}
              </div>
              <div
                className={
                  difference > 0 ? 'perentage-value ' : 'perentage-value down '
                }
              >
                {!Number.isNaN(difference) && difference > 0 ? (
                  <img
                    className="green-arrow"
                    src={ArrowUpIcon}
                    alt="arrow-up"
                  />
                ) : !Number.isNaN(difference) && difference < 0 ? (
                  <img
                    className="red-arrow"
                    src={ArrowDownIcon}
                    alt="arrow-down"
                  />
                ) : null}
                {difference !== 'N/A'
                  ? `${difference.toFixed(2).toString().replace('-', '')}%`
                  : 'N/A'}
              </div>
            </div>
          </div>
        </CardMetrics>
      </div>
    );
  };

  const renderAdSpendMetrics = (metricsName, formatNumber) => {
    let currentTotal = 0;
    let previousTotal = 0;
    let difference = 0;
    let boxActiveClass = metricsClasses[metricsName];
    let name = metricsName;
    let dropdownName = '';

    let responseKey = metricsResponseKey[name];
    // find out keys which is having true value OR seperate out selected option.
    const localSelectedSubType = _.keys(
      _.pickBy(selectedAdSpendSubType, _.identity),
    );
    [name] = localSelectedSubType;
    boxActiveClass = metricsClasses[localSelectedSubType[0]];

    // If the user selects multiple metrics with “Total” being one of them then display the total value
    if (selectedAdSpendSubType.adSpendTotal) {
      dropdownName = 'Total Spend';
      responseKey = metricsResponseKey.adSpendTotal;
      currentTotal =
        performanceCurrentTotal && performanceCurrentTotal[responseKey]
          ? performanceCurrentTotal[responseKey]
          : 0;
      previousTotal =
        performancePreviousTotal && performancePreviousTotal[responseKey]
          ? performancePreviousTotal[responseKey]
          : 0;
      difference =
        performanceDifference && performanceDifference[responseKey]
          ? performanceDifference[responseKey]
          : 'N/A';
    } // If the user selects both PPC and DSP then sum up both values
    else if (
      !selectedAdSpendSubType.adSpendTotal &&
      localSelectedSubType.length > 1
    ) {
      dropdownName = 'PPC & DSP Spend';
      const dspCurrentTotal = performanceCurrentTotal?.dsp_spends
        ? performanceCurrentTotal.dsp_spends
        : 0;

      const dspPreviousTotal = performancePreviousTotal?.dsp_spends
        ? performancePreviousTotal.dsp_spends
        : 0;

      const ppcCurrentTotal = performanceCurrentTotal?.sponsored_ad_spend
        ? performanceCurrentTotal.sponsored_ad_spend
        : 0;

      const ppcPreviousTotal = performancePreviousTotal?.sponsored_ad_spend
        ? performancePreviousTotal.sponsored_ad_spend
        : 0;

      currentTotal = dspCurrentTotal + ppcCurrentTotal;
      previousTotal = dspPreviousTotal + ppcPreviousTotal;

      difference = ((currentTotal - previousTotal) * 100) / previousTotal;
    } // If the user selects a single metric just display the value for this metric
    else {
      if (localSelectedSubType[0] === 'sponsoredAdSpend') {
        dropdownName = 'PPC Spend';
      } else if (localSelectedSubType[0] === 'dspSpend') {
        dropdownName = 'DSP Spend';
      }

      responseKey = metricsResponseKey[localSelectedSubType[0]];
      currentTotal = performanceCurrentTotal?.[responseKey]
        ? performanceCurrentTotal[responseKey]
        : 0;
      previousTotal = performancePreviousTotal?.[responseKey]
        ? performancePreviousTotal[responseKey]
        : 0;
      difference = performanceDifference?.[responseKey]
        ? performanceDifference[responseKey]
        : 'N/A';
    }

    const firstSelectedMetrics = Object.keys(selectedAdSpendSubType).find(
      (i) => selectedAdSpendSubType[i] === true,
    );

    return (
      <div className="col-lg-4 col-md-6 col-12 pr-1 pl-1 mb-3">
        <CardMetrics
          className="fix-height"
          data-tip={returnTooltipMessge(
            metricsTootlNames[firstSelectedMetrics],
          )}
          data-for={firstSelectedMetrics}
        >
          <div
            className={setPerformanceMetricsClass(name, boxActiveClass)}
            onClick={() => {
              setAdSpendMetricsToggle(name);
            }}
            role="presentation"
          >
            {' '}
            <>
              <div className="row ">
                <div className="chart-name col-6 pr-0">
                  ADVERTISING SPEND
                  {customerState?.display_ad_performance ? (
                    <FontAwesomeIcon
                      icon="fa-light fa-square-arrow-up-right"
                      color="#ff5933"
                      fontSize="13px"
                      style={{ marginLeft: '4px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setCustomerSelectedTab('performance'));

                        history.push(
                          `${PATH_CUSTOMER_DETAILS.replace(
                            ':id',
                            customerState?.id,
                          )}?tab=performance${
                            customerState?.customer_account_type
                              ? `&sub=${
                                  customerState?.customer_account_type ===
                                    'Hybrid' ||
                                  customerState?.customer_account_type ===
                                    'Seller'
                                    ? 'seller'
                                    : 'vendor'
                                }`
                              : ''
                          }&nav=adPerformance&access=external`,
                          'adPerformance',
                        );
                      }}
                    />
                  ) : null}
                </div>

                <div className="col-6 mt-n2 pl-1 text-right">
                  <BreakDown
                    id="AdvertisingSpendBreakDown"
                    ref={advertisingRef}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div
                      className={`dropdown-revenue-input ${
                        performanceGraphLoader ? `disabled` : ''
                      }`}
                      role="presentation"
                      onClick={() => {
                        setIsAdSpendDropDownOpen(!isAdSpendDropDownOpen);
                      }}
                      id="clickbox"
                    >
                      <span className="selected-list"> {dropdownName}</span>
                      <FontAwesomeIcon
                        icon="fa-regular fa-angle-down"
                        className="dropdown-arrow-icon"
                        style={{
                          transform: isAdSpendDropDownOpen
                            ? 'rotate(180deg)'
                            : '',
                        }}
                      />
                    </div>

                    {isAdSpendDropDownOpen ? (
                      <div className="dropdown-revenue-filter">
                        <ul className="options-list">
                          {adSpendMetricsSubTypes.map((item) => {
                            return (
                              <li
                                key={item.id}
                                className={
                                  isMetricsDisabled[item.value] ||
                                  (_.size(selectedPeformanceMetrics) === 4 &&
                                    !selectedAdSpendSubType[item.value])
                                    ? 'disabled'
                                    : ''
                                }
                              >
                                {' '}
                                <CheckBox className="mt-3 mb-3">
                                  <label
                                    className="check-container customer-pannel"
                                    id={item.label}
                                    htmlFor={item.value}
                                  >
                                    <input
                                      type="checkbox"
                                      id={item.value}
                                      checked={
                                        selectedAdSpendSubType[item.value]
                                      }
                                      onChange={(event) => {
                                        onHandleAdSpendeSubType(event);
                                      }}
                                      disabled={
                                        isMetricsDisabled[item.value] ||
                                        (_.size(selectedPeformanceMetrics) ===
                                          4 &&
                                          !selectedAdSpendSubType[item.value])
                                      }
                                    />
                                    <span className="checkmark" />
                                    {item.label}
                                    {item.isShowTooltip ? (
                                      <>
                                        <FontAwesomeIcon
                                          icon=" fal fa-info-circle"
                                          color={Theme.orange}
                                          fontSize="13px"
                                          style={{ marginLeft: '4px' }}
                                          data-tip={item.message}
                                          data-for="adSpendTotalInfo"
                                        />
                                        <ReactTooltip
                                          id="adSpendTotalInfo"
                                          aria-haspopup="true"
                                          place="bottom"
                                          effect="solid"
                                        />
                                      </>
                                    ) : null}
                                  </label>
                                </CheckBox>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </BreakDown>
                </div>
              </div>
            </>
            <div className="number-rate">{bindValues(currentTotal, true)}</div>
            <div className="justify-space-between ">
              <div className="vs">
                {' '}
                vs {formatNumber(previousTotal, currencySymbol, '')}
              </div>
              <div
                className={
                  difference > 0 ? 'perentage-value ' : 'perentage-value down '
                }
              >
                {!Number.isNaN(difference) && difference > 0 ? (
                  <img
                    className="green-arrow"
                    src={ArrowUpIcon}
                    alt="arrow-up"
                  />
                ) : !Number.isNaN(difference) && difference < 0 ? (
                  <img
                    className="red-arrow"
                    src={ArrowDownIcon}
                    alt="arrow-down"
                  />
                ) : null}
                {difference !== 'N/A'
                  ? `${difference.toFixed(2).toString().replace('-', '')}%`
                  : 'N/A'}
              </div>
            </div>
          </div>
        </CardMetrics>
      </div>
    );
  };

  const renderPeformanceMetrics = (metricsName, className, formatNumber) => {
    let currentTotal = 0;
    let previousTotal = 0;
    let difference = 0;
    const name = metricsName;
    let displayMetricsName = metricsName;

    if (name === 'unitsSold') {
      currentTotal =
        performanceCurrentTotal && performanceCurrentTotal?.units_sold
          ? performanceCurrentTotal.units_sold
          : 0;
      previousTotal =
        performancePreviousTotal && performancePreviousTotal?.units_sold
          ? performancePreviousTotal.units_sold
          : 0;
      difference =
        performanceDifference && performanceDifference?.units_sold
          ? performanceDifference.units_sold
          : 'N/A';

      displayMetricsName = 'Units sold';
    } else if (name === 'traffic') {
      currentTotal = performanceCurrentTotal?.traffic
        ? performanceCurrentTotal.traffic
        : 0;
      previousTotal = performancePreviousTotal?.traffic
        ? performancePreviousTotal.traffic
        : 0;
      difference = performanceDifference?.traffic
        ? performanceDifference.traffic
        : 'N/A';
    } else if (name === 'conversion') {
      currentTotal = performanceCurrentTotal?.conversion
        ? performanceCurrentTotal.conversion
        : 0;
      previousTotal = performancePreviousTotal?.conversion
        ? performancePreviousTotal.conversion
        : 0;
      difference = performanceDifference?.conversion
        ? performanceDifference.conversion
        : 'N/A';
    }

    return (
      <div className={className}>
        <CardMetrics
          className="fix-height"
          data-tip={returnTooltipMessge(tooltipNames[metricsName])}
          data-for={metricsResponseKey[metricsName]}
        >
          <div
            className={setPerformanceMetricsClass(
              name,
              metricsClasses[metricsName],
            )}
            onClick={() => {
              setBoxToggle(name);
            }}
            role="presentation"
          >
            {' '}
            <div className="chart-name">
              {displayMetricsName.toUpperCase()}{' '}
            </div>
            <div className="number-rate">
              {formatNumber(currentTotal, '', name === 'conversion' ? '%' : '')}
            </div>
            <div className="justify-space-between ">
              <div className="vs">
                {' '}
                vs{' '}
                {formatNumber(
                  previousTotal,
                  '',
                  name === 'conversion' ? '%' : '',
                )}
              </div>
              <div
                className={
                  difference > 0 ? 'perentage-value ' : 'perentage-value down '
                }
              >
                {!Number.isNaN(difference) && difference > 0 ? (
                  <img
                    className="green-arrow"
                    src={ArrowUpIcon}
                    alt="arrow-up"
                  />
                ) : !Number.isNaN(difference) && difference < 0 ? (
                  <img
                    className="red-arrow"
                    src={ArrowDownIcon}
                    alt="arrow-down"
                  />
                ) : null}
                {difference !== 'N/A'
                  ? `${difference.toFixed(2).toString().replace('-', '')}%`
                  : 'N/A'}
              </div>
            </div>
          </div>
        </CardMetrics>
      </div>
    );
  };

  const renderPerformancePanel = () => {
    return (
      <WhiteCard>
        <div className="row">
          <div className="col-xl-9 col-md-6 col-sm1-12">
            <p className="black-heading-title mt-2 pt-1 mb-4"> Performance</p>
          </div>
          {renderFilterDropDown()}
        </div>
        <div className="row mr-1 ml-1">
          {renderRevenueMetrics('revenue', useFormatNumber)}
          {renderAdSpendMetrics('adSpend', useFormatNumber)}
          {renderPeformanceMetrics(
            'traffic',
            'col-lg-4 col-md-6 col-12 pr-1 pl-1   mb-3',
            useFormatNumber,
          )}
          {renderPeformanceMetrics(
            'unitsSold',
            'col-lg-4 col-md-6 col-12 pr-1 pl-1  mb-3',
            useFormatNumber,
          )}
          {renderPeformanceMetrics(
            'conversion',
            'col-lg-4 col-md-6 col-12 pl-1 pr-0  mb-3',
            useFormatNumber,
          )}
        </div>
        {rednerPeformanceGroupByFilters()}
        <div className="clear-fix" />

        {performanceGraphLoader ? (
          <PageLoader
            component="performance-graph"
            color={Theme.orange}
            type="detail"
            width={40}
            height={40}
          />
        ) : summaryPerformanceGraphData.length !== 0 ? (
          <SummaryPerformanceGraph
            chartId="chartdiv"
            chartData={summaryPerformanceGraphData}
            currencySymbol={currencySymbol}
            selectedMetrics={selectedPeformanceMetrics}
            selectedDF={selectedTimeFrame.value}
            groupBy={groupBy}
          />
        ) : (
          <NoData>{noGraphDataMessage}</NoData>
        )}
      </WhiteCard>
    );
  };

  return (
    <>
      {showMetricsTooltipForSales.map((key) => {
        let keyName = key;
        let dataForMetric = key;
        if (
          ['revenue', 'revenue_ppc', 'revenue_dsp', 'revenue_organic'].includes(
            key,
          )
        ) {
          dataForMetric = Object.keys(selectedRevenueSubType).find(
            (i) => selectedRevenueSubType[i] === true,
          );
          keyName = revenuSubTypeKeys[dataForMetric];
        } else if (
          ['ad_spend_total', 'dsp_spends', 'sponsored_ad_spend'].includes(key)
        ) {
          dataForMetric = Object.keys(selectedAdSpendSubType).find(
            (i) => selectedAdSpendSubType[i] === true,
          );
          keyName = revenuSubTypeKeys[dataForMetric];
        }

        return (
          <Fragment key={key}>
            {!performanceCurrentTotal[keyName] &&
            !performancePreviousTotal[keyName] &&
            !performanceGraphLoader ? (
              <ReactTooltip
                id={dataForMetric}
                class="initialTextTransform"
                aria-haspopup="true"
                place="top"
                effect="solid"
                html
              />
            ) : null}
          </Fragment>
        );
      })}
      {renderPerformancePanel()}
      {/* custom date modal for performance graph */}

      <CustomDateModal
        id="BT-SummaryPerformanceReport-daterange"
        isOpen={showCustomDateModal}
        ranges={customDateState}
        onClick={() => {
          setShowCustomDateModal(false);
          setCustomDateState([
            {
              startDate: currentDate,
              endDate: currentDate,
              key: 'selection',
            },
          ]);
        }}
        onChange={(item) => {
          setCustomDateState([item.selection]);
        }}
        onApply={() => applyCustomDate('SPDate')}
        currentDate={currentDate}
        minDate={new Date('01/01/1901')}
      />
    </>
  );
}

SummaryPerformanceView.defaultProps = {
  isDisabled: false,
  showCustomDateModal: false,
  isDisabledTimeFrame: false,
  performanceGraphLoader: false,
  groupBy: '',
  currencySymbol: '',
  currency: '',
  daysDiff: 0,
  data: {},
  currentDate: {},
  selectedTimeFrame: {},
  isMetricsDisabled: {},
  performanceDifference: {},
  performanceCurrentTotal: {},
  performancePreviousTotal: {},
  selectedPeformanceMetrics: {},
  customDateState: [],
  summaryPerformanceGraphData: [],
  handleGroupBy: () => {},
  applyCustomDate: () => {},
  setCustomDateState: () => {},
  handleTimeFrameFilter: () => {},
  setShowCustomDateModal: () => {},
  setIsDisabledTimeFrame: () => {},
  renderCustomDateSubLabel: () => {},
  setSelectedPeformanceMetrics: () => {},
  generateSingleSelectDropdownHTML: () => {},
};

SummaryPerformanceView.propTypes = {
  showCustomDateModal: bool,
  performanceGraphLoader: bool,
  isDisabledTimeFrame: bool,
  isDisabled: bool,
  isApliedCustom: bool.isRequired,
  currencySymbol: string,
  groupBy: string,
  currency: string,
  id: string.isRequired,
  daysDiff: number,
  data: shape({}),
  currentDate: shape({}),
  isMetricsDisabled: shape({}),
  selectedTimeFrame: shape({}),
  performanceDifference: shape({}),
  performanceCurrentTotal: shape({}),
  performancePreviousTotal: shape({}),
  customDateState: arrayOf(shape({})),
  selectedPeformanceMetrics: shape({}),
  summaryPerformanceGraphData: arrayOf(shape({})),
  marketplace: string.isRequired,
  handleGroupBy: func,
  applyCustomDate: func,
  setCustomDateState: func,
  handleTimeFrameFilter: func,
  setIsDisabledTimeFrame: func,
  setShowCustomDateModal: func,
  renderCustomDateSubLabel: func,
  setSelectedPeformanceMetrics: func,
  generateSingleSelectDropdownHTML: func,
};
