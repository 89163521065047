import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { PageLoader, WhiteCard } from '../../common';
import { getAgreementList, getAssigneeCount } from '../../api';
import { customerAgreementTableHeaders } from '../../constants';
import { ActiveAgreementTable, GreenCheckBox } from './BrandAssetsStyle';
import {
  DspOnlyIcon,
  RecurringIcon,
  ServiceIcon,
  SignatureIcon,
} from '../../theme/images';

export default function SetupCheckList({ id }) {
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [agreementData, setAgreementData] = useState({
    data: [],
    count: null,
    assigneeCount: null,
  });

  useEffect(() => {
    setIsLoading({ loader: true, type: 'page' });
    getAgreementList(id).then((response) => {
      setAgreementData({
        data: response?.data?.results,
        count: response?.data?.count,
      });
      getAssigneeCount(id).then((res) => {
        setAgreementData({
          data: response?.data?.results,
          count: response?.data?.count,
          assigneeCount: res?.data,
        });
        setIsLoading({ loader: false, type: 'page' });
      });
    });
  }, [id]);

  const checkList = [
    {
      label: 'Sign Agreement',
      active: true,
      subtitle: '1/1 Step completed by you',
      property: '',
    },
    {
      label: 'Complete Account Setup',
      active: true,
      subtitle: agreementData?.assigneeCount?.re_assigned
        ? `${agreementData?.assigneeCount?.total_steps}/3 Steps completed by you and ${agreementData?.assigneeCount?.re_assigned} other`
        : `${agreementData?.assigneeCount?.total_steps}/3 Steps completed by you`,
      property: 'mt-3 mb-3',
    },
  ];

  const countDays = (value) => {
    const date1 = new Date();
    const date2 = new Date(value);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const displayAgreementName = (agreement) => {
    const contractType = agreement?.contract_type?.toLowerCase();

    switch (contractType) {
      case 'recurring':
        return agreement?.channel?.value === 'amazon'
          ? 'Amazon Recurring Service Agreement'
          : 'Walmart Recurring Service Agreement';
      case 'recurring (90 day notice)':
        return agreement?.channel?.value === 'amazon'
          ? 'Amazon Recurring (90 day notice) Service Agreement'
          : 'Walmart Recurring (90 day notice) Service Agreement';
      case 'dsp only':
        return 'Amazon DSP Service Agreement';
      default:
        return null;
    }
  };

  const displatAgreementType = (item) => {
    return (
      <>
        <img
          className="solid-icon"
          src={
            item?.contract_type?.toLowerCase().includes('one')
              ? ServiceIcon
              : item?.contract_type?.toLowerCase().includes('dsp')
              ? DspOnlyIcon
              : RecurringIcon
          }
          alt="recurring"
        />
        <p className="black-heading-title recurring-service mt-2 mb-0">
          {displayAgreementName(item)}
        </p>
      </>
    );
  };

  const displayAgreementStatus = (status) => {
    return (
      <div className="sign-box">
        {status?.value === 'active' ? (
          <>
            <img width="14px" src={SignatureIcon} alt="signature" /> Signed
          </>
        ) : (
          status?.label
        )}
      </div>
    );
  };

  const displayAgreementTable = () => {
    return (
      <table>
        <tbody>
          <tr className="label-heading">
            {customerAgreementTableHeaders?.map((item) => {
              return (
                <th width={item?.width} key={item?.key}>
                  {item?.label}
                </th>
              );
            })}
          </tr>
          {agreementData.data?.map((item) => (
            <tr key={item?.id}>
              <td>{displatAgreementType(item)}</td>
              <td>{displayAgreementStatus(item?.contract_status)}</td>
              <td>{dayjs(item?.start_date).format('MMM DD, YYYY')}</td>
              <td>
                {item?.contract_type?.includes('one')
                  ? 'N/A'
                  : `${countDays(item?.end_date)} days`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const displayAgreementDataMobileView = () => {
    return agreementData.data?.map((item) => (
      <React.Fragment key={item.id}>
        <div>
          <div className="label-heading ">Agreement Type</div>
          <div className="straight-line horizontal-line my-2" />
          <div className="pt-2">{displatAgreementType(item)}</div>
        </div>
        <div className="row mt-4 pt-1">
          <div className="col-5">
            <div className="label-heading  mb-2">Contract Status</div>
            {displayAgreementStatus(item?.contract_status)}
          </div>
          <div className="col-4">
            <div className="label-heading mb-2">Start Date</div>
            <div>{dayjs(item?.start_date).format('MMM DD, YYYY')}</div>
          </div>
          <div className="col-3">
            <div className="label-heading mb-2">Expires in</div>
            <div>
              {item?.contract_type?.includes('one')
                ? 'N/A'
                : `${countDays(item?.end_date)} days`}
            </div>
          </div>
        </div>
      </React.Fragment>
    ));
  };

  return (
    <div className="col-12 mb-3">
      {isLoading.loader && isLoading.type === 'page' ? (
        <PageLoader
          component="agrement-details"
          color="#FF5933"
          type="detail"
          width={40}
          height={40}
        />
      ) : (
        <>
          <fieldset className="shape-without-border extra-radius">
            <div className="row mb-3">
              <div className="col-lg-6 col-md-6 col-12">
                <p className="black-heading-title mt-2 mb-2">Setup Checklist</p>
              </div>
              <div className="col-lg-6 col-md-6 col-12 text-lg-right text-md-right text-sm-left">
                <div className="progress-bar-value">
                  <progress value="100" max="100" />
                  <div className="progress-range-text"> 100% Complete</div>
                </div>
              </div>
            </div>
            <div className="checklist-setup">
              {checkList.map((item) => (
                <GreenCheckBox className={item.property} key={item.label}>
                  <label htmlFor={item.subtitle}>
                    {item.label}
                    <div className="steps-completed">
                      {item.subtitle && item.subtitle.includes(undefined)
                        ? ''
                        : item.subtitle}
                    </div>
                    <input type="checkbox" checked={item.active} readOnly />
                    <span className="checkmark" />
                  </label>
                </GreenCheckBox>
              ))}
            </div>
          </fieldset>

          <WhiteCard className="mt-3">
            <p className="black-heading-title mt-2 ">
              Active Agreement(s) ({agreementData.count})
            </p>
            <ActiveAgreementTable>
              <div className="d-lg-block d-md-block d-none">
                {displayAgreementTable()}
              </div>
              <div className="d-lg-none d-md-none d-block">
                {displayAgreementDataMobileView()}
              </div>
            </ActiveAgreementTable>
          </WhiteCard>
        </>
      )}
    </div>
  );
}

SetupCheckList.propTypes = {
  id: PropTypes.string.isRequired,
};
