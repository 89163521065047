import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import dayjs from 'dayjs';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { arrayOf, func, string, shape } from 'prop-types';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';

import Theme from '../../../../../theme/Theme';
import DspAdPacing from './DspPacing/DspAdPacingOverlay';
import DspPacingBarGraph from './DspPacing/DspPacingBarGraph';
import BindDspResponsData from './DspAdvertising/BindDspResponsData';
import DspPerformanceChart from './DspAdvertising/DspPerformanceChart';
import DspPerformanceFilters from './DspAdvertising/DspPerformanceFilters';
import EscrowBudgetAllocationModal from './DspPacing/EscrowBudgetAllocationModal';
import DspAdPerformanceGoalsChart from './DspAdvertising/DspAdPerformanceGoalsChart';
import DspAsinAndCampaignContainer from './DspAsinAndCampaign/DspAsinAndCampaignContainer';
import DspMetrics from '../../../../BrandPartner/AdManagerAdminDashboard/DspDashboard/DspMetrics';
import {
  ArrowRightBlackIcon,
  CloseIcon,
  LeftArrowIcon,
} from '../../../../../theme/images';
import {
  CUSTOM,
  PERFORMANCE,
  dailyWeeklyMonthlyOptions,
  dateOptions,
  noGraphDataMessage,
  performanceInsightMessage,
} from '../../../../../constants';
import {
  getDSPPacingGraphData,
  getDSPPerformance,
  getDspMarketPlacesList,
  getDspPacingData,
  getGoalsMarketPlaceList,
  getSalesAndAdMinDate,
} from '../../../../../api';
import {
  PageLoader,
  WhiteCard,
  ToggleButton,
  AllocateBar,
  ToggleSwitch,
  GrayMessageBar,
  MultipleDashedLineLabel,
  CustomDateModal,
  NoData,
} from '../../../../../common';

const _ = require('lodash');
const getSymbolFromCurrency = require('currency-symbol-map');

const customDspAdPacingStyles = {
  content: {
    top: '50%',
    right: '0px',
    bottom: 'auto',
    maxWidth: '600px ',
    width: '100% ',
    maxHeight: '100%',
    overlay: ' {zIndex: 1000}',
    inset: '0% 0% 0% auto',
    marginRight: '0',
    borderRadius: '1px !important',
  },
};

export default function DspPerformanceContainer({
  customerId,
  selectedChildBrand,
  memberData,
  childBrandOptions,
  setSelectedChildBrand,
  setIsDspDashboardDisabled,
  renderDataAvailableChildList,
  getDataAvailabeleChildBrandList,
  getGoalsMarketplaceIdBySelectedMarketplace,
}) {
  const mounted = useRef(false);
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const customerState = useSelector((state) => state?.customerState?.data);
  const isHavingDsp = customerState?.having_contract_type?.is_having_dsp;

  const currentDateForDSP = useMemo(() => {
    const todaysDate = new Date();
    todaysDate.setDate(todaysDate.getDate() - 1);
    return todaysDate;
  }, []);

  const [dateFilterOptions, setDateFilterOptions] = useState(dateOptions);
  const [dspGraphLoader, setDspGraphLoader] = useState(false);
  const [dspMarketplaceLoader, setDspMarketplaceLoader] = useState(false);
  const [isDspPacingLoading, setIsDspPacingLoading] = useState(false);
  const [performancePacingFlag, setPerformancePacingFlag] =
    useState(PERFORMANCE);
  const [dspGoalsToggle, setDspGoalsToggle] = useState(false);
  const [selectedDspBox, setSelectedDspBox] = useState({
    dspImpressions: true,
  });
  const [showEscrowReallocationModal, setShowEscrowReallocationModal] =
    useState(false);
  const [showDspAdPacingModal, setShowDspAdPacingModal] = useState({
    show: false,
  });
  const [showDSPCustomDateModal, setShowDSPCustomDateModal] = useState(false);
  const [isCallDSPAsinApi, setIsCallDSPAsinApi] = useState(false);
  const [dspAdGoalChartData, setDspAdGoalChartData] = useState([]);
  const [dspGoalsMetricData, setDspGoalsMetricData] = useState({});
  const [dspGroupBy, setDSPGroupBy] = useState('daily');
  const [dspChartData, setDSPChartData] = useState([]);
  const [dspPacingChartData, setDSPPacingChartData] = useState([]);
  const [dspData, setDspData] = useState({});
  const [dspCurrentTotal, setDspCurrentTotal] = useState({});
  const [dspPreviousTotal, setDspPreviousTotal] = useState({});
  const [dspDifference, setDspDifference] = useState({});
  const [isDSPCustomDateApply, setIsDSPCustomDateApply] = useState(false);
  const [dspMarketplacesList, setDspMarketplacesList] = useState([]);
  const [selectedDspMarketplace, setSelectedDspMarketplace] = useState(
    dspMarketplacesList?.[0],
  );
  const [dspGoalMarketplaceIds, setDspGoalMarketplaceIds] = useState([]);
  const [dspGoalsMarketplaceOptions, setDspGoalsMarketplaceOptions] = useState(
    [],
  );
  const [dspCurrencySymbol, setDspCurrencySymbol] = useState('$');
  const [dspMinDate, setDspMinDate] = useState(null);
  const [selectedDspDF, setSelectedDspDF] = useState({
    value: 'week',
    label: 'Current 7 days',
    sub: 'vs Previous 7 days',
  });
  const defaultCustomDate = [
    {
      startDate: currentDateForDSP,
      endDate: currentDateForDSP,
      key: 'selection',
    },
  ];
  const [dspState, setDSPState] = useState(defaultCustomDate);
  const [customDateErrorForDsp, setCustomDateErrorForDsp] = useState(null);
  const [isAllowToSplitBalance, setIsAllowToSplitBalance] = useState(false);
  const dashboardDynamicError = useSelector(
    (state) => state?.userState?.showDynamicMsg,
  );
  let DSPDynamicErrorMessage = null;
  const sellerPlaceholder =
    dashboardDynamicError?.dsp_performance_seller?.placeholder;
  const vendorPlaceholder =
    dashboardDynamicError?.dsp_performance_vendor?.placeholder;
  if (sellerPlaceholder) {
    DSPDynamicErrorMessage = sellerPlaceholder;
  }
  if (vendorPlaceholder) {
    DSPDynamicErrorMessage = vendorPlaceholder;
  }

  const checkPermission = useCallback(() => {
    if (selectedChildBrand !== null && selectedChildBrand?.value !== 'all') {
      setIsAllowToSplitBalance(true);
    } else if (userInfo?.role?.includes('Ad Manager Admin')) {
      setIsAllowToSplitBalance(true);
    } else {
      for (const user of memberData) {
        if (user.user) {
          if (
            (user?.role_group?.name === 'DSP Ad Manager' ||
              user?.role_group?.name === 'BGS Manager' ||
              user?.role_group?.name === 'BGS') &&
            user?.user?.id === userInfo?.id
          ) {
            setIsAllowToSplitBalance(true);
            break;
          }
        }
      }
    }
  }, [memberData, userInfo, selectedChildBrand]);

  useEffect(() => {
    checkPermission();
  }, [checkPermission]);

  const bindDSPPacingResponseData = (response) => {
    const tempData = [];

    // filterout previous data in one temporary object.
    if (response && response.length) {
      let skipData = false;
      response.forEach((item) => {
        const monthYear = dayjs(item?.month_year).format('MMM YY');

        // PDV- 5569-  Hide old data till Nov 2021, so adding this condition
        if (item?.month_year === '2021-11-01') {
          skipData = true;
        }
        if (!skipData) {
          tempData.push({
            monthYear,
            invoiceAmount:
              item.invoice_amount !== null ? item.invoice_amount : 0,
            invoiceAmountUsd:
              item.invoice_amount_converted_usd !== null
                ? item.invoice_amount_converted_usd
                : 0,
            carryOver: item.carry_over !== null ? item.carry_over : 0,
            carryOverUsd:
              item.carry_over_converted_usd !== null
                ? item.carry_over_converted_usd
                : 0,
            totalInitialBudget:
              item.total_budget !== null ? item.total_budget : 0,
            totalInitialbudgetUsd:
              item.total_budget_converted_usd !== null
                ? item.total_budget_converted_usd
                : 0,
            actualSpent: item.total_spend,
            actualSpentUsd: item.total_spend_converted_usd,
            dspPacingFlag: item.dsp_pacing_flag,
            colorCode: item.dsp_pacing_flag === '0' ? '#d6a307' : '#d63649',
          });
        }
      });
    }
    return tempData;
  };

  const bindDspAdGoalsResData = (response) => {
    const finalData = [];
    if (response?.dsp_spend?.current?.length) {
      response.dsp_spend.current.forEach((item) => {
        const tempData = {};
        const currentReportDate = dayjs(item.revised_date).format('MMM D YYYY');
        tempData.date = currentReportDate;
        tempData.dspSpendCurrent = item.dsp_spend;
        tempData.dspSpendGoals = item?.goal_dsp_spend;
        tempData.dspRoasCurrent = item.roas;
        tempData.dspRoasGoals = item?.goal_roas;

        tempData.dspSpendCurrentLabel =
          item.dsp_spend !== null ? item.dsp_spend.toFixed(2) : '0';
        tempData.dspSpendGoalsLabel =
          item?.goal_dsp_spend !== null
            ? item?.goal_dsp_spend?.toFixed(2)
            : '0';
        tempData.dspRoasCurrentLabel =
          item.roas !== null ? item.roas.toFixed(2) : '0.00';
        tempData.dspRoasGoalsLabel =
          item?.goal_roas !== null ? item?.goal_roas?.toFixed(2) : '0.00';

        // to add the dotted line. we have to check null matrix and add the dummy number like 8

        // tempData.revenueTotalDashLength = item.revenue === null ? 8 : null;
        // tempData.adSpendDashLength =
        //   item.sponsored_ad_spend === null ? 8 : null;
        finalData.push(tempData);
      });
    }
    return finalData;
  };

  const getDSPData = useCallback(
    (
      selectedDailyFact,
      selectedGroupBy,
      marketplace,
      goalsMarketplaces,
      childBrand,
      onGroupByClick = false,
      startDate = null,
      endDate = null,
    ) => {
      setDspGraphLoader(true);

      getDataAvailabeleChildBrandList(
        customerId,
        'dsp',
        null,
        marketplace,
        null,
        selectedDailyFact,
        startDate,
        endDate,
      );
      getDSPPerformance(
        customerId,
        selectedDailyFact,
        selectedGroupBy,
        marketplace,
        goalsMarketplaces,
        childBrand,
        startDate,
        endDate,
      ).then((res) => {
        if (mounted.current) {
          if (res && res.status === 200) {
            if (res?.data?.dsp_spend) {
              const dspGraphData = BindDspResponsData(res?.data);
              setDSPChartData(dspGraphData);
              const dspSpend = res?.data?.dsp_spend;
              setDspCurrentTotal(dspSpend?.current_sum || {});
              setDspPreviousTotal(dspSpend?.previous_sum || {});
              setDspDifference(dspSpend?.difference_data || {});

              if (!onGroupByClick) {
                setDspGoalsMetricData(res.data?.dsp_spend?.goal_metric);
              }
              if (selectedDailyFact === CUSTOM) {
                const dspAdGoalsGraphData = bindDspAdGoalsResData(res?.data);
                setDspAdGoalChartData(dspAdGoalsGraphData);
              }
            } else {
              setDSPChartData([]);
              setDspCurrentTotal({});
              setDspPreviousTotal({});
              setDspDifference({});
            }
            setDspGraphLoader(false);
          } else {
            setDspGraphLoader(false);
          }
        }
      });
    },
    [getDataAvailabeleChildBrandList, customerId],
  );

  const getDspGoalMarketplaceIds = useCallback(
    (initialMarketplace, marketplaceList) => {
      const marketplaceId = getGoalsMarketplaceIdBySelectedMarketplace(
        initialMarketplace,
        marketplaceList,
      );
      setDspGoalMarketplaceIds(marketplaceId);
      return null;
    },
    [getGoalsMarketplaceIdBySelectedMarketplace],
  );

  const getDspGoalMarketPlacesData = useCallback(
    (initialMarketplace) => {
      let finalMarketplaces;

      getGoalsMarketPlaceList(customerId).then((res) => {
        if (res?.status === 200 && res?.data?.length) {
          finalMarketplaces = res?.data;

          const marketPlaceList = [];
          if (finalMarketplaces?.length) {
            for (const option of finalMarketplaces) {
              marketPlaceList.push({
                value: option?.name,
                label: option?.country_currency.country,
                id: option?.id,
                collapse: true,
              });
            }
            setDspGoalsMarketplaceOptions(marketPlaceList);
            getDspGoalMarketplaceIds(initialMarketplace, marketPlaceList);
          }
          setDspMarketplaceLoader(false);
        } else {
          setDspGoalsMarketplaceOptions([]);
          setDspGoalMarketplaceIds([]);
          setDspMarketplaceLoader(false);
        }
      });
    },
    [customerId, getDspGoalMarketplaceIds],
  );

  const bindDspMarketPlaces = useCallback((responseData) => {
    const tempMarketplacesList = [];

    if (responseData?.length > 0) {
      for (const item of responseData) {
        tempMarketplacesList.push({
          marketplaceId: item?.id,
          label: `${item?.country_currency?.country} ${
            item?.name !== 'all' && !item?.is_active ? '( Inactive )' : ''
          }`,
          value: item?.name,
          currency: item?.country_currency?.currency,
          isActive: item?.name === 'all' ? true : item?.is_active,
        });
      }
    }
    if (tempMarketplacesList?.length > 1) {
      tempMarketplacesList.unshift({
        marketplaceId: 'all',
        label: 'All Marketplaces',
        value: 'all',
        currency: 'USD',
      });
    }

    return tempMarketplacesList;
  }, []);

  const getDspMarketPlaces = useCallback(() => {
    setDspMarketplaceLoader(true);
    getDspMarketPlacesList(customerId).then((response) => {
      if (response?.status === 200 && response?.data?.length > 0) {
        const marketplacesData = bindDspMarketPlaces(response?.data);
        const defaultMarketplace = marketplacesData?.[0];
        setSelectedDspMarketplace(defaultMarketplace);
        setDspCurrencySymbol(
          getSymbolFromCurrency(defaultMarketplace?.currency),
        );
        getDspGoalMarketPlacesData(defaultMarketplace);
        setDspMarketplacesList(marketplacesData);
      } else {
        setSelectedDspMarketplace({});
        setDspMarketplacesList([]);
        setDspCurrencySymbol('$');
      }
    });
  }, [bindDspMarketPlaces, customerId, getDspGoalMarketPlacesData]);

  useEffect(() => {
    getDspMarketPlaces();
  }, [getDspMarketPlaces]);

  const getStringOfCustomDate = useCallback((date) => {
    let tempDate = null;

    if (date !== null) {
      tempDate = `${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}`;
    }

    return tempDate;
  }, []);

  const DSPYearAndCustomDateFilter = useCallback(
    (startDate, endDate, dspMarketplace, goalMarketplaceId) => {
      const tempGroupBy = 'daily';

      let tempStartDate = startDate;
      let tempEndDate = endDate;

      setDSPGroupBy(tempGroupBy);

      tempStartDate = getStringOfCustomDate(startDate);
      tempEndDate = getStringOfCustomDate(endDate);

      getDSPData(
        CUSTOM,
        tempGroupBy,
        dspMarketplace,
        goalMarketplaceId,
        selectedChildBrand,
        false,
        tempStartDate,
        tempEndDate,
      );
    },
    [getDSPData, getStringOfCustomDate, selectedChildBrand],
  );

  useEffect(() => {
    mounted.current = true;

    if (
      mounted.current &&
      !dspMarketplaceLoader &&
      selectedDspMarketplace?.value
    ) {
      if (selectedDspDF?.value === CUSTOM) {
        DSPYearAndCustomDateFilter(
          dspState?.[0]?.startDate,
          dspState?.[0]?.endDate,
          selectedDspMarketplace?.value,
          dspGoalMarketplaceIds,
        );
      } else {
        getDSPData(
          selectedDspDF?.value,
          'daily',
          selectedDspMarketplace?.value,
          dspGoalMarketplaceIds,
          selectedChildBrand,
          false,
          null,
          null,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    DSPYearAndCustomDateFilter,
    dspGoalMarketplaceIds,
    dspMarketplaceLoader,
    getDSPData,
    selectedChildBrand,
    selectedDspMarketplace,
  ]);

  const getDSPPacing = useCallback(
    (marketplace) => {
      setIsDspPacingLoading(true);
      getDspPacingData(customerId, marketplace, selectedChildBrand).then(
        (res) => {
          if (mounted.current) {
            if (res?.status === 200) {
              setDspData(res?.data);

              // https://bbe.atlassian.net/browse/PDV-9794
              // if bp don't have dsp contract exist but still have remaining budget and escrow then enable the dsp dashboard
              // https://bbe.atlassian.net/browse/PDV-9858 - enable dsp dashboard for inactive bp
              const remainingBudget = res?.data?.dsp_pacing?.remaining_budget;
              const escrowBalance = res?.data?.dsp_pacing?.escrow_converted_usd;

              setIsDspDashboardDisabled(
                customerState?.status?.value === 'inactive'
                  ? false
                  : !isHavingDsp &&
                      Number(remainingBudget) === 0 &&
                      Number(escrowBalance) === 0,
              );

              if (
                res?.data?.dsp_pacing === null ||
                res?.data?.dsp_pacing === undefined
              ) {
                setIsAllowToSplitBalance(false);
              } else {
                checkPermission();
              }
            }
            setIsDspPacingLoading(false);
          }
        },
      );
    },
    [
      checkPermission,
      customerId,
      customerState,
      isHavingDsp,
      selectedChildBrand,
      setIsDspDashboardDisabled,
    ],
  );

  const getPacingGraphData = useCallback(
    (marketplace) => {
      setDspGraphLoader(true);
      getDSPPacingGraphData(customerId, marketplace, selectedChildBrand).then(
        (res) => {
          if (mounted.current) {
            if (res?.status === 200) {
              if (res?.data?.dsp_pacing_graph) {
                const dspPacingGraphData = bindDSPPacingResponseData(
                  res.data.dsp_pacing_graph,
                );
                setDSPPacingChartData(dspPacingGraphData);
              } else {
                setDSPPacingChartData([]);
              }
              setDspGraphLoader(false);
            } else {
              setDspGraphLoader(false);
            }
          }
        },
      );
    },
    [customerId, selectedChildBrand],
  );

  useEffect(() => {
    mounted.current = true;

    if (mounted.current && selectedDspMarketplace?.value) {
      getDSPPacing(selectedDspMarketplace?.value);
    }
    return () => {
      mounted.current = false;
    };
  }, [selectedDspMarketplace, getDSPPacing]);

  const generateDateFilterOptions = useCallback(
    (minDate) => {
      const daysDiff = dayjs(currentDateForDSP).diff(dayjs(minDate), 'days');

      let tempDateOptions = [...dateOptions];
      if (daysDiff && tempDateOptions?.length > 0) {
        const updatedOption = tempDateOptions.map((obj) => {
          if (obj?.value === 'week' && daysDiff < 7) {
            obj.isDisabled = true;
          }
          if (
            obj?.value === 'month' &&
            daysDiff < currentDateForDSP?.getDate()
          ) {
            obj.isDisabled = true;
          }
          if (obj.value === '30days' && daysDiff < 30) {
            obj.isDisabled = true;
          }

          return obj;
        });
        tempDateOptions = [...updatedOption];
      }
      return tempDateOptions;
    },
    [currentDateForDSP],
  );

  // bind the dsp error message if data not available for selecetd custom date
  const bindErrorMessageForDsp = useCallback((minDate, customState) => {
    const dates = [];

    dates.push(
      new Date(dayjs(customState?.[0]?.startDate).format('MM/DD/YYYY')),
    );
    dates.push(new Date(dayjs(minDate).format('MM/DD/YYYY')));

    // if selected date is lower that data_availeble_date then bind the date period for showing error message
    if (dates[0] < dates[1]) {
      setCustomDateErrorForDsp({
        fromDate: dates[0],
        toDate: dates[1],
      });
    } else {
      setCustomDateErrorForDsp(null);
    }
  }, []);

  // API to get the min date of data availability for dsp
  const getMinDateForDsp = useCallback(
    (marketplace, customDate) => {
      getSalesAndAdMinDate(customerId, marketplace).then((res) => {
        if (res?.status === 200) {
          if (res?.data[0]) {
            const minDate = res?.data[0]?.dsp_performance_date;
            setDspMinDate(minDate);
            setDateFilterOptions(generateDateFilterOptions(minDate));
            bindErrorMessageForDsp(minDate, customDate);
          } else {
            setDspMinDate(null);
          }
        } else {
          setDspMinDate(null);
        }
      });
    },
    [bindErrorMessageForDsp, customerId, generateDateFilterOptions],
  );

  useEffect(() => {
    if (selectedDspMarketplace?.value)
      getMinDateForDsp(selectedDspMarketplace?.value, dspState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMinDateForDsp, selectedDspMarketplace]);

  const handleChildBrandOptions = (event) => {
    if (event.value !== selectedChildBrand?.value) {
      setSelectedChildBrand(event);
    }
  };

  const handleDspMarketplaceFilterEvents = (event) => {
    if (event?.value !== selectedDspMarketplace?.value) {
      setSelectedDspMarketplace(event);
      getDspGoalMarketplaceIds(event, dspGoalsMarketplaceOptions);
      setDspCurrencySymbol(getSymbolFromCurrency(event?.currency));
      setIsCallDSPAsinApi(true);
    }
  };

  // aaply dsp custom date
  const applyCustomDate = () => {
    if (selectedDspDF?.value !== CUSTOM)
      setSelectedDspDF({
        value: CUSTOM,
        label: 'Custom Range',
        sub: 'Select start & end date',
      });
    setIsCallDSPAsinApi(true);
    setIsDSPCustomDateApply(true);

    DSPYearAndCustomDateFilter(
      dspState?.[0]?.startDate,
      dspState?.[0]?.endDate,
      selectedDspMarketplace?.value,
      dspGoalMarketplaceIds,
    );
    bindErrorMessageForDsp(dspMinDate, dspState);
    setShowDSPCustomDateModal(false);
  };

  // handle dsp daily facts
  const handleDSPDailyFact = (event) => {
    const { value } = event;

    if (value === CUSTOM) {
      setShowDSPCustomDateModal(true);
    } else {
      setDspGoalsToggle(false);
      setIsDSPCustomDateApply(false);
      setSelectedDspDF(event);
      setDSPState(defaultCustomDate);
      setIsCallDSPAsinApi(false);
      getDSPData(
        event?.value,
        dspGroupBy,
        selectedDspMarketplace?.value,
        dspGoalMarketplaceIds,
        selectedChildBrand,
        false,
        null,
        null,
      );
      bindErrorMessageForDsp(dspMinDate, defaultCustomDate);
    }
  };

  // handle dsp group by toggle events
  const handleDSPGroupBy = (value) => {
    if (value !== dspGroupBy) {
      setDSPGroupBy(value);

      if (selectedDspDF?.value === CUSTOM) {
        const tempStartDate = getStringOfCustomDate(dspState?.[0]?.startDate);
        const tempEndDate = getStringOfCustomDate(dspState?.[0]?.endDate);

        getDSPData(
          selectedDspDF?.value,
          value,
          selectedDspMarketplace?.value,
          dspGoalMarketplaceIds,
          selectedChildBrand,
          true,
          tempStartDate,
          tempEndDate,
        );
      } else {
        getDSPData(
          selectedDspDF?.value,
          value,
          selectedDspMarketplace?.value,
          dspGoalMarketplaceIds,
          selectedChildBrand,
          true,
          null,
          null,
        );
      }
    }
  };

  // handle performance/pacing toggle events
  const handlePeformancePacingToggle = (value) => {
    setPerformancePacingFlag(value);
    if (value === PERFORMANCE) {
      if (selectedDspDF?.value === CUSTOM) {
        const tempStartDate = getStringOfCustomDate(dspState?.[0]?.startDate);
        const tempEndDate = getStringOfCustomDate(dspState?.[0]?.endDate);

        getDSPData(
          selectedDspDF?.value,
          dspGroupBy,
          selectedDspMarketplace?.value,
          dspGoalMarketplaceIds,
          selectedChildBrand,
          false,
          tempStartDate,
          tempEndDate,
        );
      } else {
        getDSPData(
          selectedDspDF?.value,
          dspGroupBy,
          selectedDspMarketplace?.value,
          dspGoalMarketplaceIds,
          selectedChildBrand,
          false,
          null,
          null,
        );
      }
    } else {
      getPacingGraphData(selectedDspMarketplace?.value);
    }
  };

  // handle dsp modal On close events
  const handleDspModalOnCloseEvents = () => {
    if (selectedDspDF.value === CUSTOM) {
      const tempStartDate = getStringOfCustomDate(dspState?.[0]?.startDate);
      const tempEndDate = getStringOfCustomDate(dspState?.[0]?.endDate);

      getDSPData(
        selectedDspDF?.value,
        dspGroupBy,
        selectedDspMarketplace?.value,
        dspGoalMarketplaceIds,
        selectedChildBrand,
        false,
        tempStartDate,
        tempEndDate,
      );
    } else {
      getDSPData(
        selectedDspDF?.value,
        dspGroupBy,
        selectedDspMarketplace?.value,
        dspGoalMarketplaceIds,
        selectedChildBrand,
        false,
        null,
        null,
      );
    }
    getDSPPacing(selectedDspMarketplace?.value);
    if (performancePacingFlag === 'pacing') {
      getPacingGraphData(selectedDspMarketplace?.value);
    }
  };

  // handle escrow balance modal onClick events
  const handleEscrowModalOnClickEvents = () => {
    if (showDspAdPacingModal.show) {
      setShowEscrowReallocationModal(false);

      handleDspModalOnCloseEvents();
    } else {
      setShowEscrowReallocationModal(false);
      getDSPPacing(selectedDspMarketplace?.value);
    }
  };

  const setBoxClasses = (name, classValue, graphType) => {
    let selectedClass = '';
    if (graphType === 'dsp') {
      if (Object.prototype.hasOwnProperty.call(selectedDspBox, name)) {
        selectedClass = `order-chart-box ${classValue} fix-height`;
      } else if (_.size(selectedDspBox) === 4) {
        selectedClass = 'order-chart-box fix-height disabled';
      } else {
        selectedClass = 'order-chart-box fix-height';
      }
    }
    return selectedClass;
  };

  const setBoxToggle = (name, GraphType) => {
    if (GraphType === 'dsp') {
      if (
        Object.prototype.hasOwnProperty.call(selectedDspBox, name) &&
        _.size(selectedDspBox) > 1
      ) {
        setSelectedDspBox(_.omit(selectedDspBox, [name]));
      } else if (_.size(selectedDspBox) < 4) {
        if (
          _.size(_.assign(selectedDspBox, { [name]: true })) === 2 &&
          dspGoalsToggle
        ) {
          toast.info('Maximum two goals at a time');
        }
        setSelectedDspBox(_.omit(_.assign(selectedDspBox, { [name]: true })));
      }
    }
  };

  const displayDspPacingLabel = () => {
    const dspPacingFlag = dspData?.dsp_pacing?.dsp_pacing_flag;
    const pacingLabel =
      String(dspPacingFlag) === '1'
        ? 'Overspending'
        : String(dspPacingFlag) === '0'
        ? 'On Track'
        : String(dspPacingFlag) === '-1'
        ? 'Underspending'
        : null;
    if (dspPacingFlag) {
      return (
        <span
          className={pacingLabel?.includes('On Track') ? 'green' : ''}
          data-cy="BT-dsp-spendStatus"
        >
          {pacingLabel}
          <img
            className="right-arrow-icon "
            width="18px"
            src={ArrowRightBlackIcon}
            alt="arrow"
          />
          &nbsp;
        </span>
      );
    }
    return null;
  };

  // Return class name according to status and label
  const displayDspPacingClassName = () => {
    const dspPacingFlag = dspData?.dsp_pacing?.dsp_pacing_flag;
    if (String(dspPacingFlag) === '1') return 'orange-text'; // Overspending
    if (String(dspPacingFlag) === '0') return 'green'; // On Track
    if (String(dspPacingFlag) === '-1') return 'orange-text'; // Underspending
    return `${Theme.gray40}`; // Default
  };

  const displayMonth = () => {
    const currentDateOfMonth = new Date().getDate();
    if (currentDateOfMonth === 1) {
      const todayDate = new Date();
      todayDate.setMonth(todayDate.getMonth() - 1, 1);
      return dayjs(new Date(todayDate)).format('MMMM');
    }
    return dayjs(new Date()).format('MMMM');
  };

  // display dsp pacing metrics value
  const displayDspPacingMetricsValue = (metricsValue) => {
    const valueString = `${
      metricsValue < 0 ? '-' : ''
    }${dspCurrencySymbol}${metricsValue
      .toFixed(2)
      .toString()
      .replace('-', '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    return valueString;
  };

  const renderDspPacingData = () => {
    return (
      <div
        data-cy="BT-dsp-monthlyBudgetPacing"
        className="gray-normal-text mb-4 mt-1"
      >
        {dspData?.dsp_pacing
          ? `Monthly Budget Pacing ( ${displayMonth()} )`
          : ''}
        <br />
        {/* dsp pacing label - overspending and underspending */}
        {!userInfo?.role?.includes('Customer') ? (
          <span
            data-cy="BT-DspPacing-label"
            className="orange-text"
            role="presentation"
            onClick={() => setShowDspAdPacingModal({ show: true })}
          >
            {displayDspPacingLabel()}
          </span>
        ) : null}
        {/* Remaining Budget and Escrow balance status and link */}
        <div
          className="remaing-label d-inline-block"
          data-cy="BT-dsp-performancePacingFlag-label"
        >
          {performancePacingFlag === PERFORMANCE
            ? `Remaining Budget :`
            : ` Total Escrow Balance :`}
          <span
            className={displayDspPacingClassName()}
            role="presentation"
            data-cy="BT-dsp-performancePacingFlag-value"
            style={{
              cursor: `${
                isAllowToSplitBalance && selectedDspMarketplace?.value
                  ? 'pointer'
                  : 'auto'
              }`,
            }}
            onClick={() => {
              if (
                isAllowToSplitBalance &&
                selectedDspMarketplace?.value !== 'all'
              ) {
                setShowEscrowReallocationModal(true);
              }
            }}
          >
            {' '}
            {performancePacingFlag === PERFORMANCE
              ? dspData?.dsp_pacing?.remaining_budget
                ? displayDspPacingMetricsValue(
                    dspData.dsp_pacing.remaining_budget,
                  )
                : `${dspCurrencySymbol}0`
              : dspData?.dsp_pacing?.escrow
              ? displayDspPacingMetricsValue(dspData.dsp_pacing.escrow)
              : `${dspCurrencySymbol}0`}
          </span>
        </div>
      </div>
    );
  };

  // display dsp performance filters
  const displayDspPerformanceFilters = () => {
    return (
      <DspPerformanceFilters
        isApiCall={dspGraphLoader || dspMarketplaceLoader}
        isDSPCustomDateApply={isDSPCustomDateApply}
        customerState={customerState}
        selectedDspDF={selectedDspDF}
        selectedChildBrand={selectedChildBrand}
        selectedDspMarketplace={selectedDspMarketplace}
        dspState={dspState}
        dateFilterOptions={dateFilterOptions}
        childBrandOptions={childBrandOptions}
        dspMarketplacesList={dspMarketplacesList}
        handleDSPDailyFact={handleDSPDailyFact}
        handleChildBrandOptions={handleChildBrandOptions}
        handleDspMarketplaceFilterEvents={handleDspMarketplaceFilterEvents}
      />
    );
  };

  // render pacing & performance toggle button
  const renderPacingAndPerformanceToggleButton = () => {
    return (
      <ToggleButton>
        {!userInfo?.role?.includes('Customer') ? (
          <div className="days-container ">
            <ul className="days-tab">
              <li
                id="BT-dsp-performance-toggle"
                data-cy="BT-dsp-performanceToggle"
              >
                <input
                  className="d-none"
                  type="radio"
                  id="peformanceCheck"
                  name="performanceRadioDefault"
                  value={performancePacingFlag}
                  checked={performancePacingFlag === PERFORMANCE}
                  onClick={() => handlePeformancePacingToggle(PERFORMANCE)}
                  onChange={() => {}}
                />
                <label htmlFor="peformanceCheck">Performance</label>
              </li>
              <li id="BT-dsp-pacing-toggle" data-cy="BT-dsp-pacingToggle">
                <input
                  className="d-none"
                  type="radio"
                  id="pacingCheck"
                  name="pacingRadioDefault"
                  value={performancePacingFlag}
                  checked={performancePacingFlag === 'pacing'}
                  onClick={() => handlePeformancePacingToggle('pacing')}
                  onChange={() => {}}
                />
                <label htmlFor="pacingCheck">Pacing</label>
              </li>
            </ul>
          </div>
        ) : null}
      </ToggleButton>
    );
  };

  // render Dsp Dynamic Error Message
  const renderDspDynamicErrorMessage = () => {
    return DSPDynamicErrorMessage !== null ? (
      <GrayMessageBar
        message={DSPDynamicErrorMessage}
        dataCy="BT-dsp-error-message"
      />
    ) : null;
  };

  // render dsp performance header section
  const renderDspAdHeaderSection = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-5 col-lg-4 col-4 pr-0">
            <p className="black-heading-title mt-0 mt-md-2 mb-2 mb-md-0">
              DSP Ad Performance
            </p>
            <div className="d-none d-md-block">{renderDspPacingData()}</div>
          </div>
          <div className="col-md-7 col-lg-8 col-8 pl-0 text-right">
            {renderPacingAndPerformanceToggleButton()}
          </div>
          <div className="col-12 d-md-none">{renderDspPacingData()}</div>
          <div className="col-12">{renderDspDynamicErrorMessage()}</div>
        </div>

        {/* display list of child brands whos data is available */}
        {renderDataAvailableChildList('dsp')}
        {selectedDspDF.value !== CUSTOM ? (
          <GrayMessageBar
            message={performanceInsightMessage?.dsp}
            dataCy="BT-dsp-insight-message"
          />
        ) : null}
        <AllocateBar className="mb-3">
          <div className="row">
            <div className="col-8">
              <div
                className="remaing-label"
                data-cy="BT-dsp-performancePacingFlag-monthLabel"
              >
                {performancePacingFlag === PERFORMANCE
                  ? `Remaining Budget (${displayMonth()}): `
                  : `Total Escrow Balance : `}
                <span
                  style={{ fontWeight: 'bold' }}
                  data-cy="BT-dsp-performancePacingFlag-monthValue"
                >
                  {performancePacingFlag === PERFORMANCE
                    ? dspData?.dsp_pacing?.remaining_budget
                      ? displayDspPacingMetricsValue(
                          dspData.dsp_pacing.remaining_budget,
                        )
                      : `${dspCurrencySymbol}0`
                    : dspData?.dsp_pacing?.escrow
                    ? displayDspPacingMetricsValue(dspData.dsp_pacing.escrow)
                    : `${dspCurrencySymbol}0`}
                </span>
              </div>{' '}
            </div>
            {isAllowToSplitBalance &&
            selectedDspMarketplace?.value !== 'all' ? (
              <div className="col-4">
                <div
                  className="allocate-balance cursor"
                  role="presentation"
                  onClick={() => {
                    setShowEscrowReallocationModal(true);
                  }}
                >
                  Allocate Balance{' '}
                  <img
                    className="orange-left-arrow mb-1"
                    src={LeftArrowIcon}
                    alt=""
                  />
                </div>
                <div className="clear-fix" />
              </div>
            ) : null}
          </div>
        </AllocateBar>
      </>
    );
  };

  const renderDSPGroupBy = () => {
    const previousClassName =
      _.size(selectedDspBox) === 1
        ? `${_.keys(selectedDspBox)[0]} block`
        : 'darkGray block';
    return (
      <>
        <div className="row mt-4 mb-3">
          {_.size(selectedDspBox) <= 2 ? (
            <div className="col-md-6 col-sm-12 order-md-1 order-2 mt-2">
              <ul className="rechart-item">
                <li key="recent">
                  <div className="weeks">
                    <span
                      className={
                        _.size(selectedDspBox) === 1
                          ? `${_.keys(selectedDspBox)[0]} circle`
                          : 'darkGray circle'
                      }
                    />

                    <span> {dspGoalsToggle ? 'Metrics' : 'Recent'}</span>
                  </div>
                </li>
                {selectedDspDF?.value !== CUSTOM ? (
                  <li key="previous">
                    <MultipleDashedLineLabel
                      label="Previous"
                      className={previousClassName}
                    />
                  </li>
                ) : dspGoalsToggle ? (
                  <li key="goals">
                    <MultipleDashedLineLabel
                      label="Goals"
                      className={previousClassName}
                    />
                  </li>
                ) : null}
              </ul>
            </div>
          ) : (
            <div className="col-md-6 col-sm-12 order-md-1 order-2 mt-2" />
          )}

          <div className="col-md-6 col-sm-12 order-md-2 order-1">
            <ToggleButton>
              <div className="days-container ">
                <ul
                  className={
                    dspGraphLoader ? 'disabled-tab days-tab' : 'days-tab'
                  }
                >
                  {dailyWeeklyMonthlyOptions?.map((item) => {
                    return (
                      <li key={item?.dspId}>
                        <input
                          className="d-none"
                          type="radio"
                          id={item?.dspId}
                          name="flexRadioDefault1"
                          value={item?.value}
                          checked={dspGroupBy === item?.value}
                          onChange={() => handleDSPGroupBy(item?.value)}
                        />
                        <label htmlFor={item?.dspId}>{item?.label}</label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </ToggleButton>
          </div>
        </div>
      </>
    );
  };

  const showGoalsToggle = () => {
    return (
      <ToggleSwitch
        className="custom-switch mt-2"
        // key={`sellerDetails${graphIndex}${option.id}`}
      >
        <label
          htmlFor="dspGoalsToggle"
          className={`label-name ${
            selectedDspDF.value === CUSTOM
              ? Object.keys(dspGoalsMetricData)?.length
                ? ''
                : 'disabled'
              : 'disabled'
          }`}
          // key={`${idName}${graphIndex}`}
        >
          Goals
          <input
            type="checkbox"
            className="label-name"
            id="dspGoalsToggle"
            onClick={() => {
              setDspGoalsToggle(!dspGoalsToggle);
              setSelectedDspBox({ dspSpend: true });
            }}
            onChange={() => {}}
            checked={dspGoalsToggle}
          />
          <span className="slider round" />
        </label>
      </ToggleSwitch>
    );
  };

  // display error message for custom date
  const renderErrorMessage = () => {
    let { fromDate } = customDateErrorForDsp;
    let { toDate } = customDateErrorForDsp;

    fromDate = dayjs(fromDate).format('MM/DD/YYYY');
    toDate = dayjs(toDate).subtract(1, 'day').format('MM/DD/YYYY');

    return (
      <div className="row">
        <div className="col-12 mt-2 mb-3">
          <div className="normal-text-black first-white-card">
            <GrayMessageBar
              message={`There is no DSP advertising data available from ${fromDate}
      to ${toDate}.`}
              dataCy="BT-dsp-data-message"
            />
          </div>
        </div>
      </div>
    );
  };

  // display dsp ad pacing ovarlay
  const displayDspPacingOverlay = () => {
    return (
      <Modal
        isOpen={showDspAdPacingModal?.show}
        style={customDspAdPacingStyles}
        ariaHideApp={false}
        contentLabel="Add team modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => setShowDspAdPacingModal({ show: false })}
          role="presentation"
        />
        <DspAdPacing
          dspData={dspData}
          isDspPacingLoading={isDspPacingLoading}
          currencySymbol={dspCurrencySymbol}
          customerId={customerId}
          marketplace={selectedDspMarketplace?.value}
          marketplaceOptions={dspMarketplacesList}
          onModalClose={() => {
            handleDspModalOnCloseEvents();
          }}
          isAllowToSplitBalance={isAllowToSplitBalance}
          setShowEscrowReallocationModal={setShowEscrowReallocationModal}
        />
      </Modal>
    );
  };

  // render escroe budget reallocation modal
  const renderEscrowBudgetAllocationModal = () => {
    return (
      <EscrowBudgetAllocationModal
        id="BT-escrowAllocation"
        customerId={customerId}
        marketplace={selectedDspMarketplace?.value}
        isOpen={showEscrowReallocationModal}
        onClick={() => {
          handleEscrowModalOnClickEvents();
        }}
      />
    );
  };

  const returnMinDate = () => {
    return dspMinDate === null ? new Date('01/01/2019') : new Date(dspMinDate);
  };

  const renderCustomDateModal = () => {
    return (
      <CustomDateModal
        id="BT-adperformance-daterange"
        isOpen={showDSPCustomDateModal}
        ranges={dspState}
        onClick={() => {
          setShowDSPCustomDateModal(false);

          if (selectedDspDF?.value !== CUSTOM) {
            setDSPState([
              {
                startDate: currentDateForDSP,
                endDate: currentDateForDSP,
                key: 'selection',
              },
            ]);
          }
        }}
        onChange={(item) => {
          setDSPState([item?.selection]);
        }}
        onApply={() => {
          applyCustomDate('DSP');
        }}
        currentDate={currentDateForDSP}
        minDate={returnMinDate()}
      />
    );
  };

  // render ad performance modals
  const renderDspPerformanceModals = () => {
    return (
      <>
        {displayDspPacingOverlay()}
        {showEscrowReallocationModal
          ? renderEscrowBudgetAllocationModal()
          : null}
        {renderCustomDateModal()}
      </>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
      {displayDspPerformanceFilters()}
      <WhiteCard className="mt-3 mb-3">
        {renderDspAdHeaderSection()}
        {performancePacingFlag === PERFORMANCE ? (
          <>
            <DspMetrics
              currencySymbol={dspCurrencySymbol}
              dspCurrentTotal={dspCurrentTotal}
              dspDifference={dspDifference}
              dspPreviousTotal={dspPreviousTotal}
              setBoxToggle={setBoxToggle}
              setBoxClasses={setBoxClasses}
              isApiCall={dspGraphLoader || dspMarketplaceLoader}
              dspGoalsMetricData={dspGoalsMetricData}
              dspGoalsToggle={dspGoalsToggle}
            />
            {showGoalsToggle()}
            {selectedDspDF?.value === CUSTOM && customDateErrorForDsp !== null
              ? renderErrorMessage()
              : null}
            {renderDSPGroupBy()}
            {dspGraphLoader || dspMarketplaceLoader ? (
              <PageLoader
                component="performance-graph"
                color="#FF5933"
                type="detail"
                width={40}
                height={40}
              />
            ) : dspChartData?.length >= 1 && !dspGoalsToggle ? (
              <DspPerformanceChart
                chartId="dspChart"
                chartData={dspChartData}
                currencySymbol={dspCurrencySymbol}
                selectedMetrics={selectedDspBox}
                selectedDF={selectedDspDF?.value}
                groupBy={dspGroupBy}
              />
            ) : dspAdGoalChartData?.length && dspGoalsToggle ? (
              <DspAdPerformanceGoalsChart
                chartId="goalsDspAdChart"
                chartData={dspAdGoalChartData}
                currencySymbol={dspCurrencySymbol}
                selectedMetrics={selectedDspBox}
                selectedDF={selectedDspDF?.value}
              />
            ) : (
              <NoData>{noGraphDataMessage}</NoData>
            )}
          </>
        ) : (
          <>
            {dspGraphLoader || dspMarketplaceLoader ? (
              <PageLoader
                component="performance-graph"
                color="#FF5933"
                type="detail"
                width={40}
                height={40}
              />
            ) : dspPacingChartData.length >= 1 ? (
              <DspPacingBarGraph
                chartId="dspPacingBarGraph"
                chartData={dspPacingChartData}
                currencySymbol={dspCurrencySymbol}
              />
            ) : (
              <NoData>{noGraphDataMessage}</NoData>
            )}
          </>
        )}
        <div className="straight-line horizontal-line mb-4 mt-3" />
        {/* ********** PDV-8422 Asin performance in dsp ad section ********** */}
        <DspAsinAndCampaignContainer
          customerId={customerId}
          childBrandId={selectedChildBrand?.value}
          selectedDF={selectedDspDF?.value}
          selectedMarketplace={selectedDspMarketplace?.value}
          adState={dspState}
          currencySymbol={dspCurrencySymbol}
          isCallDSPAsinApi={isCallDSPAsinApi}
          setIsCallDSPAsinApi={setIsCallDSPAsinApi}
        />
        {renderDspPerformanceModals()}
      </WhiteCard>
    </>
  );
}

DspPerformanceContainer.defaultProps = {
  customerId: null,
  selectedChildBrand: {},
  memberData: [],
  childBrandOptions: [],
  setSelectedChildBrand: () => {},
  setIsDspDashboardDisabled: () => {},
  renderDataAvailableChildList: () => {},
  getDataAvailabeleChildBrandList: () => {},
  getGoalsMarketplaceIdBySelectedMarketplace: () => {},
};

DspPerformanceContainer.propTypes = {
  customerId: string,
  selectedChildBrand: shape({}),
  childBrandOptions: arrayOf(shape({})),
  memberData: arrayOf(shape({})),
  setSelectedChildBrand: func,
  setIsDspDashboardDisabled: func,
  renderDataAvailableChildList: func,
  getDataAvailabeleChildBrandList: func,
  getGoalsMarketplaceIdBySelectedMarketplace: func,
};
