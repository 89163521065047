import React, { useCallback, useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';

import InventoryScoreCard from './InventoryScoreCard';
import PositiveFeedbackCard from './PositiveFeedbackCard';
import OrderIssuesCard from './OrderIssuesCard';
import SummaryInvoicesCard from './SummaryInvoicesCard';
import {
  getSummaryInvoice,
  getSummarySellerPerformance,
} from '../../../../../api';

export default function SummarySellerHealthView({ selectedMarketplace, id }) {
  const mounted = useRef(false);
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const isSuperAdmin = userInfo?.role?.includes('Super Admin');

  const [invScore, setInvScore] = useState([
    { name: 'Inventory', value: 'N/A' },
    { name: 'Total', value: 1000 },
  ]);

  const [feeback, setFeeback] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);

  const onGetSummaryInvoices = useCallback(() => {
    getSummaryInvoice(id)
      .then((res) => {
        if (mounted.current) {
          if (res?.status === 200) {
            setInvoiceData(res.data);
          }
        }
      })
      .catch(() => {});
  }, [id]);

  const onGetSummaryHealthData = useCallback(() => {
    getSummarySellerPerformance(id, selectedMarketplace)
      .then((res) => {
        if (mounted.current) {
          if (res?.status === 200) {
            if (res?.data?.pf_oi_is?.length) {
              const lastUpdated = res.data.pf_oi_is[0]?.latest_date;
              res.data.pf_oi_is[0].latest_date =
                dayjs(lastUpdated).format('MMM DD YYYY');

              setFeeback(res?.data?.pf_oi_is[0]);

              const ipiValue = parseFloat(
                res.data.pf_oi_is[0]?.inventory_performance_index,
              );
              if (Number.isNaN(ipiValue)) {
                setInvScore([
                  {
                    name: 'Inventory',
                    value: 'N/A',
                  },
                  {
                    name: 'Total',
                    value: 1000,
                  },
                ]);
              } else {
                setInvScore([
                  {
                    name: 'Inventory',
                    value: ipiValue,
                  },
                  {
                    name: 'Total',
                    value: 1000 - ipiValue,
                  },
                ]);
              }
            } else {
              setInvScore([
                { name: 'Inventory', value: 'N/A' },
                { name: 'Total', value: 1000 },
              ]);
            }
          }
        }
      })
      .catch(() => {
        setInvScore([
          { name: 'Inventory', value: 'N/A' },
          { name: 'Total', value: 1000 },
        ]);
      });
  }, [id, selectedMarketplace]);

  useEffect(() => {
    mounted.current = true;
    onGetSummaryHealthData();
    onGetSummaryInvoices();
  }, [onGetSummaryHealthData, onGetSummaryInvoices]);

  return (
    <div className="row mt-3">
      {/* https://bbe.atlassian.net/browse/PDV-9229 - hide seller health
            data for user other than super Admin */}
      {isSuperAdmin ? (
        <>
          <InventoryScoreCard dspData={feeback} pieData={invScore} />
          <PositiveFeedbackCard dspData={feeback} />
          <OrderIssuesCard dspData={feeback} />
        </>
      ) : null}
      <SummaryInvoicesCard invoiceData={invoiceData} />
    </div>
  );
}

SummarySellerHealthView.defaultProps = {};

SummarySellerHealthView.propTypes = {
  selectedMarketplace: string.isRequired,
  id: string.isRequired,
};
